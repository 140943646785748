import Prospects from './Prospects'
import { EventBus } from '@/services/event-bus'
import ProspectsApi from '@/services/api/Prospects'
import router from '@/router'
import { setLSItem } from '@/utils/localStorage'

export default {
  async onMessage(evt) {
    //TODO: Any type of security?
    if (evt.origin === 'https://app.hubspot.com')
      setLSItem(`is-hubspot-widget`, true)

    if (evt.data.type) {
      if (evt.data.type.indexOf('prospect') > -1) {
        Prospects.handle(evt.data.type, evt.data.data)
      }
      if (evt.data.type === 'call-number') {
        console.log('onMessage', evt)
        const number = evt.data.data?.number
        const externalId = evt.data.data.externalId
        let currentPhoneRecord = {
          number: evt.data.data.number,
          externalId,
        }
        if (externalId) {
          const resp = await ProspectsApi.getProspectByExternalId(externalId)
          const contact = resp.data[0]
          if (contact) {
            currentPhoneRecord = {
              ...currentPhoneRecord,
              prospect: contact,
              fullName: contact.full_name,
            }
            EventBus.$emit('set-current-phone-record', currentPhoneRecord)
            return
          }
        }
        if (number) {
          ProspectsApi.getProspects({
            filtersQuery: `phoneNumbers=${number}`,
          }).then((resp) => {
            const contact = resp.data[0]
            if (contact) {
              currentPhoneRecord = {
                ...currentPhoneRecord,
                prospect: contact,
                fullName: contact.full_name,
              }
              EventBus.$emit('set-current-phone-record', currentPhoneRecord)
            }
          })
        }
        if (router.currentRoute?.path !== '/dial')
          router.push('/dial').finally(() => {
            setTimeout(
              () => EventBus.$emit('call-now', currentPhoneRecord),
              1000
            )
          })
        else
          setTimeout(() => {
            EventBus.$emit('call-now', currentPhoneRecord)
          }, 1000)
      }
      if (evt.data.type === 'fetch-contact') {
        console.log('fetch-contact message', evt)
        const externalId = evt.data.data?.externalId
        ProspectsApi.getCrmContactByExternalId(externalId).then((res) => {
          parent.postMessage(
            {
              type: 'contact-fetched',
              data: res,
            },
            '*'
          )
        })
      }
    }
  },
  addListners() {
    if (window.addEventListener) {
      window.addEventListener('message', this.onMessage, false)
    } else {
      window.attachEvent('onMessage', this.onMessage)
    }
  },
}
