import CrmApi from '@/services/api/Crm'

import { getContactsWithActivity } from '@/utils/helpers'
const _unionBy = require('lodash/unionBy')

// initial state
const state = () => ({
  crmContacts: [],
  status: '',
  selectedContacts: [],
  excludedContactIds: [],
  selectedReport: null,
})

// getters
const getters = {
  crmContacts: (state) => state.crmContacts,
  loadingStatus: (state) => state.status === 'loading',
  selectedContacts: (state) => state.selectedContacts,
  excludedContactIds: (state) => state.excludedContactIds,
  selectedReport: (state) => state.selectedReport,
}

// actions
const actions = {
  async fetchCrmContacts({ state, dispatch, commit }, options = {}) {
    return new Promise((resolve, reject) => {
      commit('request')
      CrmApi.getCrmProspects({
        ...options,
        listId: state.selectedReport?.external_id,
      })
        .then((resp) => {
          if (resp.data) {
            dispatch('markSelections', { options, data: resp.data })
            if (options.useInfScroll && options.infScrollOps?.start !== 0) {
              commit(
                'setCrmContacts',
                _unionBy(state.crmContacts, resp.data, 'external_id').map(
                  (item, index) => ({
                    ...item,
                    index,
                    driveStates: item.driveStates || [],
                  })
                )
              )
            } else {
              commit(
                'setCrmContacts',
                resp.data.map((item, index) => ({
                  ...item,
                  index,
                  driveStates: item.driveStates || [],
                }))
              )
            }
          }

          commit('success')
          resolve(resp)
        })
        .catch((err) => {
          // ignore error if the req. was aborted
          if (!err.aborted) {
            commit('error')
            reject(err)
          }
        })
    })
  },
  updateCrmContact({ commit }, contact) {
    return new Promise((resolve, reject) => {
      CrmApi.updateCrmContact(contact)
        .then((resp) => {
          commit('setCrmContact', resp)
          resolve(resp)
        })
        .catch((err) => reject(err))
    })
  },

  markSelections({ state, commit }, payload) {
    const { options, data } = payload
    const newData =
      options.useInfScroll && options.infScrollOps?.start !== 0
        ? _unionBy(state.crmContacts, data, 'external_id')
        : data
    if (options.selectAll) commit('setSelectedContacts', newData)
    else if (options.selectAllExclude && state.excludedContactIds.length) {
      const selectAllRespectExclude = newData.filter((resPerson) => {
        return !state.excludedContactIds.some(
          (excPerson) => excPerson === resPerson.external_id
        )
      })
      commit('setSelectedContacts', selectAllRespectExclude)
    }
  },
}

// mutations
const mutations = {
  setCrmContacts(state, data) {
    state.crmContacts = data.length > 0 ? data : []
  },
  setCrmContact(state, newContact) {
    const index = state.crmContacts.findIndex(
      (contact) => contact.external_id === newContact.external_id
    )
    if (index !== -1)
      state.crmContacts = [
        ...state.crmContacts.slice(0, index),
        newContact,
        ...state.crmContacts.slice(index + 1),
      ]
  },
  setSelectedContacts(state, contacts) {
    state.selectedContacts = contacts
  },
  setExcludedContactIds(state, excludedContactIds) {
    state.excludedContactIds = excludedContactIds
  },
  resetContactSelections(state) {
    state.selectedContacts = []
    state.excludedContactIds = []
  },
  setSelectedReport(state, report) {
    state.selectedReport = report
  },
  setContactsActivity(state, activityList) {
    state.crmContacts = getContactsWithActivity(state.crmContacts, activityList)
  },
  markContactsImported(state, data) {
    state.crmContacts = state.crmContacts.map((contact) => {
      const mapping = (data || []).find(
        (item) => item.external_id === contact.external_id
      )
      if (mapping)
        return {
          ...contact,
          id: mapping.id,
          driveStates: contact.driveStates || [],
        }

      return contact
    })
  },
  addTagToContacts(state, data) {
    state.crmContacts = state.crmContacts.map((contact) => {
      return !data?.prospects.some((i) => i.id === contact.id)
        ? contact
        : {
            ...contact,
            prospectTags: [...(contact.prospectTags || []), data.prospectTag],
          }
    })
  },
  request(state) {
    state.status = 'loading'
  },
  success(state) {
    state.status = 'success'
  },
  error(state) {
    state.status = 'error'
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
