import store from '@/store/index'

const _unionBy = require('lodash/unionBy')
const _uniqBy = require('lodash/uniqBy')
const _isObject = require('lodash/isObject')
const _isArray = require('lodash/isArray')
const _transform = require('lodash/transform')
const _isString = require('lodash/isString')

const countries = require('country-data').countries

const MINUTES_IN_HOUR = 60
const MINUTES_IN_DAY = 1440

import * as lamejs from 'lamejs'
import { getTimeZones } from '@vvo/tzdb'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import { uniq } from 'lodash'
import { DateTime } from 'luxon'
import { TIME_FORMAT } from '@/utils/dateHelpers'
import { UserSettings } from '@/constants'

const getSettingByKey = store?.getters['user/getSettingByKey']

const defaultCountryCode = () =>
  getSettingByKey(UserSettings.ISO_COUNTRY_CODE) || 'US'

export function generateUuid() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

// TODO: Maybe should remove these generate/isValid functions and just use uuid package
export function isValidUuid(uuid) {
  return /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi.test(
    uuid
  )
}

export function formatTemplateState(template) {
  if (template.state === 'archived') return 'Archived'
  else if (template.state === 'engaged') return 'Active'
  else return 'Draft'
}

const plurEdgeCases = {
  person: 'people',
  is: 'are',
}

export function pluralize(count, noun, suffix = 's') {
  if (Object.hasOwnProperty.call(plurEdgeCases, noun)) {
    return `${count !== 1 ? plurEdgeCases[noun] : noun}`
  } else {
    return `${noun}${count !== 1 ? suffix : ''}`
  }
}

export function capitalizeFirstLetter(word) {
  if (!word) return ''
  else return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
}

export function htmlToText(html) {
  var span = document.createElement('span')
  span.innerHTML = html
  const text = span.textContent || span.innerText
  span.remove()
  if (!text && html.includes('img')) return 'image'
  return text
}

export function openInTab(url) {
  if (!url) return
  if (!url.includes('http')) window.open(`http://${url}`).focus()
  else window.open(url).focus()
}

export function extractDomain(urlString) {
  if (!urlString) return
  let domain
  if (!urlString.includes('http')) domain = new URL(`http://${urlString}`)
  else domain = new URL(urlString)
  return domain.hostname
}

export function getDriveIconColor(state) {
  switch (state) {
    case 'in_progress':
      return 'green'
    case 'paused':
      return '#ffae00'
    case 'completed':
      return 'dark green'
    case 'finished':
      return 'dark green'
  }
}

export function getDriveStateIcon(state) {
  switch (state) {
    case 'in_progress':
      return 'mdi-play'
    case 'paused':
      return 'mdi-pause'
    case 'completed':
      return 'mdi-check'
    case 'finished':
      return 'mdi-flag'
  }
}

export function getContactFormattedAddress(contact) {
  const {
    address_city,
    address_country,
    address_state,
    address_street,
    address_street_2,
    address_zip,
  } = contact
  const formattedAddress = `${address_street ? address_street + ',' : ''} ${
    address_street_2 ? address_street_2 + ', ' : ''
  } ${address_city ? address_city + ', ' : ''} ${
    address_state ? address_state + ', ' : ''
  } ${address_zip ? address_zip + ', ' : ''} ${
    address_country ? address_country + ', ' : ''
  }`
  return formattedAddress.slice(0, -2)
}

export function convertMillisToMinutesAndSeconds(
  milis,
  stopWatchFormat = false
) {
  let minutes = Math.floor(milis / 60000)
  let seconds = Math.floor((milis % 60000) / 1000)
  if (stopWatchFormat) {
    minutes = minutes.toString().length === 1 ? `0${minutes}` : minutes
    seconds = seconds.toString().length === 1 ? `0${seconds}` : seconds
  }
  return stopWatchFormat ? `${minutes}:${seconds}` : `${minutes}m ${seconds}s`
}

export function convertSecondsToHoursMinutesSeconds(
  secNum,
  stopWatchFormat = true
) {
  const h = Math.floor(secNum / 3600)
  const m = Math.floor((secNum - h * 3600) / 60)
  const s = Math.round(secNum - h * 3600 - m * 60)
  if (stopWatchFormat) {
    return (
      (h > 0 ? `${h < 10 ? '0' : ''}${h}:` : '') +
      `${m < 10 ? '0' : ''}${m}:` +
      `${s < 10 ? '0' : ''}${s}`
    )
  }
  return [
    ...(h > 0 ? [`${h}h`] : []),
    ...(m > 0 ? [`${m}m`] : []),
    `${s}s`,
  ].join(' ')
}

export function getContactsWithActivity(contacts, activityList) {
  return contacts.map((contact) => {
    const propsectActivity = activityList.find(
      (entry) => entry.x === contact.id
    )
    if (propsectActivity) {
      return {
        ...contact,
        activity: {
          openCount: parseInt(propsectActivity['Mailings.openCountDistinct']),
          clickCount: parseInt(propsectActivity['Mailings.clickCount']),
          replyCount: parseInt(propsectActivity['Mailings.replyCount']),
        },
      }
    } else return { ...contact, activity: null }
  })
}

export function getDrivesWithEmailAnalytics(drives, activityList) {
  return drives.map((drive) => {
    const driveEmails = activityList.find((entry) => entry.x === drive.id)
    if (driveEmails) {
      return {
        ...drive,
        emailSummary: {
          deliveredCount: parseInt(driveEmails['DriveMailings.deliveredCount']),
          openCount: parseInt(driveEmails['DriveMailings.openCountDistinct']),
          clickCount: parseInt(driveEmails['DriveMailings.clickCountDistinct']),
          replyCount: parseInt(driveEmails['DriveMailings.replyCount']),
        },
      }
    } else return { ...drive, emailSummary: null }
  })
}

export function getDrivesWithCallAnalytics(drives, activityList, v2 = false) {
  return drives.map((drive) => {
    const driveCalls = activityList.find((entry) => entry.x === drive.id)
    if (driveCalls) {
      return {
        ...drive,
        callSummary: {
          callCount: parseInt(driveCalls['DriveCalls.outboundCallCount']),
          answeredCount: parseInt(driveCalls['DriveCalls.answeredCount']),
          humanAnswerCount: v2
            ? parseInt(driveCalls['DriveCalls.humanAnsweredCountV2'])
            : parseInt(driveCalls['DriveCalls.humanAnsweredCount']),
          machineAnswerCount: v2
            ? driveCalls['DriveCalls.machineAnsweredCountV2']
            : parseInt(driveCalls['DriveCalls.machineAnsweredCount']),
        },
      }
    } else return { ...drive, callSummary: null }
  })
}

export function getDrivesWithActionAnalytics(drives, activityList) {
  return drives.map((drive) => {
    const driveCompletedTasks = activityList.find(
      (entry) =>
        entry.xValues[0] === drive.id && entry.xValues[1] === 'drive_task'
    )
    const driveCompletedLinkedin = activityList.find(
      (entry) =>
        entry.xValues[0] === drive.id &&
        entry.xValues[1]?.includes('drive_linkedin')
    )
    if (driveCompletedTasks || driveCompletedLinkedin) {
      return {
        ...drive,
        actionSummary: {
          tasksCount: parseInt(
            driveCompletedTasks?.['Actions.completedCount'] || '0'
          ),
          linkedInCount: parseInt(
            driveCompletedLinkedin?.['Actions.completedCount'] || '0'
          ),
        },
      }
    } else return { ...drive, actionSummary: null }
  })
}

export function getDrivesWithOutcomeAnalytics(drives, activityList) {
  return drives.map((drive) => {
    const positiveOutcomes = activityList.find(
      (entry) =>
        entry.xValues[0] === drive.id && entry.xValues[1] === 'positive'
    )
    const neutralOutcomes = activityList.find(
      (entry) => entry.xValues[0] === drive.id && entry.xValues[1] === 'neutral'
    )
    const negativeOutcomes = activityList.find(
      (entry) =>
        entry.xValues[0] === drive.id && entry.xValues[1] === 'negative'
    )
    if (positiveOutcomes || neutralOutcomes || negativeOutcomes) {
      return {
        ...drive,
        outcomeSummary: {
          positive: parseInt(positiveOutcomes?.['DriveStates.count'] || '0'),
          neutral: parseInt(neutralOutcomes?.['DriveStates.count'] || '0'),
          negative: parseInt(negativeOutcomes?.['DriveStates.count'] || '0'),
        },
      }
    } else return { ...drive, outcomeSummary: null }
  })
}

export function getDrivesWithEngagedCounts(drives, activityList) {
  return drives.map((drive) => {
    const driveEngagments = activityList.find((entry) => entry.x === drive.id)
    if (driveEngagments) {
      return {
        ...drive,
        ever_engaged_prospect_count: parseInt(
          driveEngagments['DriveStates.count'] || '0'
        ),
        current_engaged_prospect_count: parseInt(
          driveEngagments['DriveStates.currentCount'] || '0'
        ),
      }
    } else
      return {
        ...drive,
        ever_engaged_prospect_count: null,
        current_engaged_prospect_count: null,
      }
  })
}

export function truncate(str, charLimit = 65, placeholder) {
  if (!str) return placeholder || ''
  return str.length > charLimit ? str.slice(0, charLimit) + '...' : str
}

export function getTableSelectionMessage(
  tableState,
  totalItems,
  entityName,
  selected,
  excluded,
  allCb,
  someCb,
  excludeCb,
  limit = null,
  limitExclusions = null,
  infScrollEnabled = false
) {
  if (tableState === 'some-selected') {
    const totalSelections = limit
      ? limit - (limitExclusions?.length || 0)
      : selected.length
    return {
      buttonText: infScrollEnabled
        ? `Select all ${entityName}s`
        : `Select all ${totalItems} ${entityName}s`,
      messageText: `${totalSelections} ${
        entityName + (totalSelections > 1 ? 's' : '')
      } selected.`,
      selections: totalSelections,
      action: someCb,
    }
  } else if (tableState === 'all-selected') {
    return {
      buttonText: `Clear selections`,
      messageText: infScrollEnabled
        ? `All ${entityName}s have been selected.`
        : `All ${totalItems} ${entityName}s have been selected.`,
      selections: totalItems,
      action: allCb,
    }
  } else if (tableState === 'all-selected-exclude') {
    return {
      buttonText: `Clear selections`,
      messageText: infScrollEnabled
        ? excluded.length
          ? `All except ${excluded.length} ${entityName}s have been selected.`
          : `All ${entityName}s have been selected.`
        : `${totalItems - excluded.length} ${entityName}s have been selected.`,
      action: excludeCb,
      selections: totalItems - excluded.length,
    }
  } else {
    return {
      buttonText: ``,
      messageText: ``,
      action: () => null,
    }
  }
}

export function getTableStateOnItemSelection(
  item,
  value,
  key = 'id',
  tableState,
  selected,
  totalItems,
  itemsPerPage
) {
  // store gets updated after this is called, need to track updated selections locally
  let newTempSelected
  if (value) {
    newTempSelected = _unionBy(selected, [item], key)
  } else {
    newTempSelected = selected.filter((selectedItem) => {
      return item[key] !== selectedItem[key]
    })
  }

  // checked
  if (value) {
    if (newTempSelected.length === totalItems) return 'all-selected'
    if (tableState === '') return 'some-selected'
  }
  // unchecked
  else {
    if (
      (!newTempSelected.length && !tableState?.startsWith('all-selected')) ||
      (selected.length == 1 && tableState == 'all-selected') ||
      (selected.length == 1 &&
        tableState == 'all-selected-exclude' &&
        totalItems <= itemsPerPage)
    )
      return ''

    if (tableState === 'all-selected') {
      if (selected.length === totalItems) return 'some-selected'
      return 'all-selected-exclude'
    }
  }
  return tableState
}

export function getTableStateOnAllSelected(
  items,
  value,
  key = 'id',
  tableState,
  selected,
  totalItems
) {
  // store gets updated after this is called, need to track updated selections
  let newTempSelected
  if (value) {
    newTempSelected = _unionBy(selected, items, key)
  } else {
    newTempSelected = selected.filter((contact) => {
      return !items.some((selContact) => selContact[key] === contact[key])
    })
  }

  // checked
  if (value) {
    if (
      tableState === 'all-selected-exclude' ||
      newTempSelected.length >= totalItems
    ) {
      return 'all-selected'
    } else {
      return 'some-selected'
    }
  }
  // unchecked
  else {
    return ''
  }
}

export function getFullName(firstName, lastName) {
  return [firstName?.trim(), lastName?.trim()].join(' ').trim()
}

export function getPercentageIncrease(val1, val2, useVal2 = false) {
  if (val1 === 0 && val2) return useVal2 ? val2 : 100
  else if (val1 === 0 && val2 === 0) return 0

  return Math.ceil(((val2 - val1) / val1) * 100)
}

// Accepts mins
// Returns [days, mins, hours]
export function convertMinsToDaysMinsHrs(mins) {
  const days = Math.floor(mins / MINUTES_IN_DAY)
  const remainingMinutesAfterDays = mins % MINUTES_IN_DAY
  const hours = Math.floor(remainingMinutesAfterDays / MINUTES_IN_HOUR)
  const minutes = remainingMinutesAfterDays % MINUTES_IN_HOUR
  return [days, hours, minutes]
}

// Accepts  [days, mins, hours]
// Returns mins
export function convertDaysMinsHrsToMins([days, hours, minutes]) {
  return days * MINUTES_IN_DAY + hours * MINUTES_IN_HOUR + minutes
}

export function convertSecondsToHrsMins(seconds) {
  const hrs = Math.floor(seconds / 3600)
  const mins = Math.floor((seconds % 3600) / 60)
  return `${hrs}h ${mins}m`
}

export function convertSecondsToMinsSecs(seconds) {
  const mins = Math.floor(seconds / 60)
  let secs = Math.floor(seconds % 60)
  secs = secs.toString().length < 2 ? `0${secs}` : secs
  return `${mins}:${secs}`
}

export function convertSecondsToHrsMinsSecs(
  seconds,
  alwaysShowSeconds = false
) {
  const hrs = Math.floor(seconds / 3600)
  const mins = Math.floor((seconds % 3600) / 60)
  let secs = Math.floor(seconds % 60)
  secs = secs.toString().length < 2 ? `0${secs}` : secs

  if (alwaysShowSeconds) return `${hrs}h ${mins}m ${secs}s`

  return !hrs && mins
    ? !secs
      ? `${mins}m`
      : `${mins}m ${secs}s`
    : !hrs && !mins
    ? `${secs}s`
    : `${hrs}h ${mins}m`
}

export function sendSnackMessage(msg, severity) {
  store.commit('snackbar/setSnack', {
    snack: msg,
    snackType: severity,
  })
}

export function convertBlobToMP3(blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onloadend = function () {
      const audioData = reader.result

      const audioContext = new AudioContext()
      audioContext
        .decodeAudioData(audioData)
        .then((decodedData) => {
          const audioBuffer = convertToMP3(decodedData)
          const file = new File([audioBuffer], 'audio.mp3', {
            type: 'audio/mp3',
          })
          resolve(file)
        })
        .catch(reject)
    }
    reader.onerror = reject
    reader.readAsArrayBuffer(blob)
  })
}

export function convertToMP3(decodedData) {
  const mp3encoder = new lamejs.Mp3Encoder(
    decodedData.numberOfChannels,
    decodedData.sampleRate,
    128
  )
  const samples = decodedData.getChannelData(0)
  const buffer = []

  for (let i = 0; i < samples.length; i++) {
    const sample = samples[i] * 32767
    buffer.push(sample)
  }

  const left = buffer
  const right = buffer

  const mp3Data = mp3encoder.encodeBuffer(left, right)
  const mp3Buffer = new Int8Array(mp3Data.length)

  for (let i = 0; i < mp3Data.length; i++) {
    mp3Buffer[i] = mp3Data[i]
  }

  return mp3Buffer
}

export function sanitizePhoneNumber(number) {
  return number
    ? `${number.includes('+') ? '+' : ''}${number.replace(/\D/g, '')}`
    : ''
}

export function cleanNumberBeforeCall(number, countryCode) {
  let cleanedNumber = number

  // Remove any invalid characters
  const invalidCharacters = ["'", '`', '"', 'ph:', '-', '(', ')', ' ', '.']
  cleanedNumber = invalidCharacters.reduce(
    (acc, char) => acc.replaceAll(char, ''),
    number
  )

  return parsePhoneNumberFromString(
    cleanedNumber,
    countryCode || defaultCountryCode()
  )?.number
}

export const getStyleVal = (elm, css) => {
  return window.getComputedStyle(elm, null).getPropertyValue(css)
}

export function toSentenceCase(string) {
  // Change key snake case or camel case to sentence case with spaces
  return string
    .replace(/_/g, ' ')
    .replace(/([A-Z])/g, ' $1')
    .replace(/^./, function (str) {
      return str.toUpperCase()
    })
    .split(' ')
    .map(
      (word, index) =>
        word.charAt(0)[index == 0 ? 'toUpperCase' : 'toLowerCase']() +
        word.slice(1)
    )
    .join(' ')
}

export function getTextColorFromBG(bgColor) {
  // Calculate contrast using YIQ color space
  if (!bgColor) return 'black'
  const color = bgColor.replace('#', '')
  const r = parseInt(color.substr(0, 2), 16)
  const g = parseInt(color.substr(2, 2), 16)
  const b = parseInt(color.substr(4, 2), 16)
  const yiq = (r * 299 + g * 587 + b * 114) / 1000
  return yiq >= 189 ? 'black' : 'white'
}

// text example: 'I am a great dane and I love to run in the park.'
// fontSize example: '14px'
// fontType example: 'Arial'
// containerWidth example: '140px'
export function truncateText(
  text = '',
  fontSize,
  fontType = 'Arial',
  containerWidth
) {
  const canvas = document.createElement('canvas')
  const context = canvas.getContext('2d')

  // Convert font size to a numeric value
  const numericFontSize = parseFloat(fontSize)

  // Extract the numeric value of containerWidth
  const numericContainerWidth = parseFloat(containerWidth)

  // Set the font
  context.font = fontSize + ' ' + fontType

  // Measure the text width
  const textWidth = context.measureText(text).width

  if (textWidth <= numericContainerWidth) {
    return text
  } else {
    const ellipsisWidth = context.measureText('...').width

    // Calculate the maximum number of characters that fit within the container
    const maxChars = Math.floor(
      (numericContainerWidth - ellipsisWidth) / numericFontSize
    )

    // Truncate the text and add ellipsis
    const truncatedText = text.substring(0, maxChars) + '...'
    return truncatedText
  }
}

export function friendlyUrl(url) {
  return url.replace('https://', '').replace('http://', '')
}

export function isSameNumber(phone, phone2) {
  if (!phone || !phone2) return false
  if (phone?.id === phone2?.id) return true
  const parsedPhone = parsePhoneNumberFromString(
    phone?.number || phone?.to || ''
  )?.number
  const parsedPhone2 = parsePhoneNumberFromString(
    phone2?.to || phone2?.number || ''
  )?.number
  return parsedPhone === parsedPhone2
}

export function getUserInitials(firstName = '', lastName = '', fullName = '') {
  if (firstName && lastName)
    return (firstName[0] || '') + (lastName[0] || '').toUpperCase()
  else if (fullName) {
    const [fname, lname] = fullName.split(' ')
    if (fname && lname) return (fname[0] || '') + (lname[0] || '').toUpperCase()
    return (fullName[0] + fullName[1]).toUpperCase()
  }
  return ''
}

/**
 * @description Takes a raw phone number string and returns a formatted international
 * phone number string, ex. +1 517 862 6434
 * @param {string} number
 * @param {string} [defaultCountryCode] - The ISO country code to use for parsing the number.
 * Defaults to US.
 * @returns {string}
 */
export function formattedInternationalPhoneNumber(
  number,
  defaultCountryCode = 'US'
) {
  if (!number) return
  const phoneNumberObject = parsePhoneNumberFromString(
    number,
    defaultCountryCode
  )
  return phoneNumberObject ? phoneNumberObject.formatInternational() : null
}

export function buildExternalCrmLink(crm, object, isAccount = false) {
  if (!crm || !object || !object.external_id) return
  if (crm.crm_provider === 'salesforce') {
    const type = isAccount ? 'Account' : object.is_lead ? 'Lead' : 'Contact'
    return `${crm.crm_endpoint}/lightning/r/${type}/${object.external_id}/view`
  } else if (crm.crm_provider === 'hubspot') {
    if (object.type === 'external_form') {
      return `https://app.hubspot.com/submissions/${crm.external_org_id}/form/${object.formId}/submissions/${object.external_id}`
    }
    const type = isAccount ? '0-2' : '0-1'
    return `https://app.hubspot.com/contacts/${crm.external_org_id}/record/${type}/${object.external_id}`
  } else if (crm.crm_provider === 'pipedrive') {
    return isAccount
      ? `${crm.crm_endpoint}/organization/${object.external_id}`
      : `${crm.crm_endpoint}/person/${object.external_id}`
  } else if (crm.crm_provider === 'gohighlevel') {
    return isAccount
      ? `${crm.crm_endpoint}v2/location/${crm.external_org_id}/businesses/${object.external_id}`
      : `${crm.crm_endpoint}/v2/location/${crm.external_org_id}/contacts/detail/${object.external_id}`
  } else return
}

// CUBE DATA TRANSFORMERS
export function transformCountValues(obj) {
  function transformValue(value) {
    if (_isObject(value)) {
      // If it's an object or an array, apply transformation recursively
      return transformCountValues(value, print)
    }
    return value // Return the value unchanged if not an object
  }

  if (_isArray(obj) && obj.length) {
    // If the current object is an array, transform each element
    return obj.map(transformValue)
  } else {
    // Otherwise, transform keys and values of the object
    return _transform(obj, (result, value, key) => {
      if (
        key.toLowerCase().includes('count') &&
        _isString(value) &&
        !isNaN(value)
      ) {
        // If key includes 'count', value is a string, and value can be converted to a number,
        // convert the value to an integer
        result[key] = parseInt(value)
      } else {
        result[key] = transformValue(value)
      }
    })
  }
}

// Format a number to a string with commas
export function formatNumberWithCommas(number) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export function renameDuplicates(items, key) {
  const nameCount = {} // Record of names and their counts
  const transformedItems = items.map((item) => {
    const itemName = item[key]
    let newItem = { ...item } // Create a shallow copy to avoid modifying the original item

    if (nameCount[itemName]) {
      // If the name already exists, append a suffix and increment the count
      newItem[key] = `${itemName} (${nameCount[itemName]})`
      nameCount[newItem[key]] = 1 // Initialize count for the new name
      nameCount[itemName] += 1 // Increment count for the original name
    } else {
      // If the name doesn't exist, initialize its count
      nameCount[itemName] = 1
    }

    return newItem
  })

  return transformedItems
}

export function generateTimeOptionsWithDate(date = new Date()) {
  const options = []
  // Format the date part for the value
  const datePart = date.toISOString().split('T')[0]

  for (let hour = 0; hour < 24; hour++) {
    for (let minute = 0; minute < 60; minute += 15) {
      // Converting 24-hour format to 12-hour format
      const hour12 = hour % 12 === 0 ? 12 : hour % 12
      // Formatting AM/PM
      const amPm = hour < 12 ? 'am' : 'pm'
      // Formatting the text and value
      const text = `${hour12}:${minute.toString().padStart(2, '0')}${amPm}`
      const value = `${datePart} ${hour.toString().padStart(2, '0')}:${minute
        .toString()
        .padStart(2, '0')}`

      options.push({ text, value })
    }
  }
  return options
}

export function getTimezoneGroups(addTime = false) {
  let data = Object.values(
    getTimeZones().reduce((acc, tz) => {
      // Get the name and id of the current time zone
      const offset = tz.currentTimeOffsetInMinutes
      let name = offset
        ? `${tz.alternativeName} (GMT${
            offset > 0 ? `+${offset / 160}` : offset / 60
          })`
        : `${tz.alternativeName} (GMT)`
      let id = tz.group.join(',')
      // Check if the name already exists in the accumulator object
      if (acc[name]) {
        // If yes, append the id to the existing id with a comma
        acc[name].id += ',' + id

        // Convert back to array to remove duplicates
        let uniqIds = uniq(acc[name].id.split(','))
        acc[name].id = uniqIds.join(',')
        acc[name].timezones = uniqIds
      } else {
        // If not, create a new entry with the name and id
        acc[name] = { name, id, timezones: id.split(',') }
      }
      // Return the updated accumulator object
      return acc
    }, {})
  )
  if (addTime) {
    const now = DateTime.now()

    data = data.map((item) => ({
      ...item,
      time: now.setZone(item.id.split(',')[0]).toFormat(TIME_FORMAT),
    }))
  }

  return data
}

export function getNormalizedTimezones(addTime = false) {
  let data = getTimeZones().map((tz) => ({
    name: tz.name,
    id: tz.name,
    offset: tz.currentTimeOffsetInMinutes
      ? `GMT${
          tz.currentTimeOffsetInMinutes > 0
            ? `+${tz.currentTimeOffsetInMinutes / 160}`
            : tz.currentTimeOffsetInMinutes / 60
        }`
      : 'GMT',
  }))
  if (addTime) {
    const now = DateTime.now()

    data = data.map((item) => ({
      ...item,
      time: now.setZone(item.id.split(',')[0]).toFormat(TIME_FORMAT),
    }))
  }

  return data
}

export function isValidEmail(email) {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
  return emailRegex.test(email)
}

export function copyToClipboard(contentToCopy) {
  navigator.clipboard.writeText(contentToCopy)
}

export function areQueriesSame(query1 = '', query2 = '', ignoreParam) {
  let query1ToConsider = query1
    ?.split('&')
    .filter((param) => !param.includes(ignoreParam))
    .join('&')
  let query2ToConsider = query2
    ?.split('&')
    .filter((param) => !param.includes(ignoreParam))
    .join('&')

  return query1ToConsider === query2ToConsider
}

export function getActionIcon(action = {}) {
  const { action_type } = action
  if (action_type.includes('call'))
    return action.call?.direction === 'inbound'
      ? 'phone-incoming'
      : 'mdi-phone-outgoing'

  if (action_type.includes('auto_email')) return 'mdi-send-clock'
  if (action_type.includes('email')) return 'mdi-email'
  if (action_type.includes('task')) return 'mdi-check-bold'
  if (action_type.includes('linkedin')) return 'mdi-linkedin'
  if (action_type.includes('note')) return 'mdi-note'
}

export function sanitizeImagesInMailing(bodyHTML, maxImgWidth = '200px') {
  return bodyHTML.replaceAll('<img', `<img style="max-width: ${maxImgWidth}"`)
}

export function transcriptionToHTML(transcription) {
  // Use a regular expression to match the pattern for each line
  return transcription
    .split('\n')
    .map((line) => {
      // Match the timestamp and character's full name
      const match = line.match(/^\[(\d{2}:\d{2}:\d{2})\]\s*(.+?):\s*(.*)/)
      if (match) {
        // Destructure the match
        const [, timestamp, character, text] = match
        // Wrap the timestamp and character in <strong> tags and add a line break
        return `<strong>[${timestamp}] ${character}:</strong> ${text.trim()}<br/>`
      }
      // Return the line as is if it doesn't match
      return line
    })
    .join('')
}

export const mergeDuplicateParams = (params, paramName) => {
  let newParams = [...params]
  const firstOccurenceIndex = findParamIndex(params, paramName)
  if (firstOccurenceIndex != -1) {
    const secondOccurenceIndex = findParamIndex(
      params,
      paramName,
      firstOccurenceIndex
    )
    if (secondOccurenceIndex != -1) {
      newParams[firstOccurenceIndex] =
        params[firstOccurenceIndex] +
        ',' +
        params[secondOccurenceIndex].slice(paramName.length)
      newParams.splice(secondOccurenceIndex, 1)
    }
  }
  return newParams
}

export const findParamIndex = (params, substring, skipIndex) =>
  params.findIndex((param, index) =>
    skipIndex != null
      ? index !== skipIndex && param.includes(substring)
      : param.includes(substring)
  )

export function getGroupedCallOutcomes(
  callOutcomes,
  allGroupLabel = 'All',
  allGroupValue = 'all'
) {
  const groups = {}

  callOutcomes.forEach((item) => {
    const groupName = item.group?.name || allGroupLabel
    const groupValue = item.group?.value || allGroupValue

    if (!groups[groupName])
      groups[groupName] = { name: groupName, value: groupValue, outcomes: [] }

    groups[groupName].outcomes.push(item)
  })

  return Object.values(groups)
}

export function resizeHTMLImgs(html) {
  return html.replaceAll('<img', '<img style="max-width: 100%"')
}

export function getFlagFromCountryCode(code) {
  return countries[code]?.emoji
}

export function getNameFromCountryCode(code) {
  return countries[code]?.name
}

export function generateProspectFieldsConfig(
  savedConfig,
  defaultProspectFieldsConfig,
  customProspectFields
) {
  let newConfig = []

  if (!savedConfig.length) {
    newConfig = [
      ...defaultProspectFieldsConfig,
      ...customProspectFields?.map((i) => ({
        text: i.name,
        value: i.key,
        show: true,
        type: i.type,
        displayWithCommas: i.display_with_commas,
        mergedWith: [],
      })),
    ]
  } else {
    savedConfig.forEach((itemInConfig) => {
      // Find in default symbo fields
      const itemInDefaultFields = defaultProspectFieldsConfig.find(
        (item) => item.value === itemInConfig.value
      )
      // Find in custom prospect fields from store
      const itemInCustomProspectFields = customProspectFields.find(
        (item) => item.key === itemInConfig.value
      )

      // Found: in default symbo fields OR in custom prospect fields from store
      if (itemInDefaultFields || itemInCustomProspectFields) {
        const itemObj = itemInDefaultFields || itemInCustomProspectFields
        // This field is found and will be added to the config
        // We need to check if the fields merged with this field should also be added or not
        let newMergedWith = []

        if (itemInConfig.mergedWith?.length) {
          itemInConfig.mergedWith.forEach((mergedItem) => {
            // Find in default symbo fields
            const mergedItemInDefaultFields = defaultProspectFieldsConfig.find(
              (item) => item.value === mergedItem.value
            )
            // Find in custom prospect fields from store
            const mergedItemInCustomProspectFields = customProspectFields.find(
              (item) => item.key === mergedItem.value
            )

            let mergedItemObj =
              mergedItemInDefaultFields || mergedItemInCustomProspectFields
            // Found: in default symbo fields OR in custom prospect fields from store
            if (mergedItemObj)
              newMergedWith.push({
                ...mergedItem,
                name: mergedItemObj.name,
              })
          })
        }
        newConfig.push({
          ...itemInConfig,
          name: itemObj.name,
          mergedWith: newMergedWith,
        })
      }

      // Not found: Probably the field got deleted
      // This field won't be a part of the config
      // We need to make sure we don't lose any merged field inside this field
      else if (itemInConfig.mergedWith?.length) {
        itemInConfig.mergedWith.forEach((mergedItem) => {
          // Find in default symbo fields
          const mergedItemInDefaultFields = defaultProspectFieldsConfig.find(
            (item) => item.value === mergedItem.value
          )
          // Find in custom prospect fields from store
          const mergedItemInCustomProspectFields = customProspectFields.find(
            (item) => item.key === mergedItem.value
          )

          // Found: in default symbo fields OR in custom prospect fields from store
          if (mergedItemInDefaultFields || mergedItemInCustomProspectFields) {
            newConfig.push(mergedItem)
          }
        })
      }
    })
  }

  // Verify if a new custom field was added or removed or updated
  // In this case we need to add or remove or update the object (name, options, etc.) in the config
  // Show/hide property would be the same as it was in the config object
  customProspectFields.forEach((i) => {
    let isInMergedWith = false // This checks if the custom field is found but in a mergedWith of some config item
    const configIndex = newConfig.findIndex((item) => {
      if (item.value === i.key) return true

      isInMergedWith = (item.mergedWith || []).some(
        (mergedItem) => mergedItem.value === i.key
      )
      return isInMergedWith
    })

    if (configIndex === -1) {
      newConfig.push({
        name: i.name,
        text: i.name,
        value: i.key,
        show: true,
        type: i.type,
        displayWithCommas: i.display_with_commas,
        mergedWith: [],
      })
    } else {
      if (isInMergedWith) {
        newConfig[configIndex] = {
          ...newConfig[configIndex],
          mergedWith: [
            {
              ...newConfig[configIndex].mergedWith[0],
              name: i.name,
              text: i.name,
            },
          ],
        }
      } else {
        newConfig[configIndex] = {
          ...newConfig[configIndex],
          name: i.name,
          text: i.name,
        }
      }
    }
  })

  // Ensure all default prospect fields are present in the config
  defaultProspectFieldsConfig.forEach((i) => {
    const isInConfig = newConfig.find(
      (item) =>
        item.value === i.value ||
        (item.mergedWith || []).some(
          (mergedItem) => mergedItem.value === i.value
        )
    )

    if (!isInConfig) {
      newConfig.push({
        text: i.text,
        name: i.text,
        value: i.value,
        show: true,
        type: i.type,
        default_field: true,
        mergedWith: [],
        icon: i.icon,
      })
    }
  })

  return _uniqBy(newConfig, 'value')
}
