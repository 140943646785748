import api from './Api'

export default {
  uploadCsvFile(csvFile) {
    const formData = new FormData()
    formData.append('csv', csvFile)
    return api.post(`/csv/prospects`, formData, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    })
  },
  getLocalPresenceNumber(options = {}) {
    let path = `/localPresenceNumbers`
    let pathParams = []

    const { to, provider } = options
    if (to) pathParams.push('to=' + encodeURIComponent(to))
    if (provider) pathParams.push('provider=' + provider)

    if (pathParams.length) path += '?' + pathParams.join('&')

    return api.get(path, { abortKey: 'GET_LOCAL_PRESENCE_NUMBER' })
  },
  getCallRecordingCompliance(options = {}) {
    let path = `/callRecordingCompliance`
    let pathParams = []

    const { to } = options
    if (to) pathParams.push('to=' + encodeURIComponent(to))

    if (pathParams.length) path += '?' + pathParams.join('&')

    return api.get(path, { abortKey: 'GET_CALL_RECORDING_COMPLIANCE' })
  },
  replaceMergeTags(payload) {
    return api.post(`/helpers/replaceMergeTags`, payload)
  },
  sendLog(payload) {
    return api.post('/helpers/sendLog', payload)
  },
}
