import store from '../../store/index'
import { formatDistance } from '@/utils/formatters/formatDistance.js'
import {
  convertToUserTimeZone,
  convertToUTC,
  getLuxonObj,
  isBefore,
  isAfter,
  isToday,
  isSame,
  isHoliday,
} from '../dateHelpers'
import { DateTime } from 'luxon'
import { convertLuxonToBZDaysLuxon } from '@/utils/formatters/businessDays'
const _replace = require('lodash/replace')

const DATE_TIME_FORMAT = 'MMM d, yyyy h:mm a (z)' // Sep 20th 2020, 8:01 pm EDT
const DATE_FORMAT = 'MMM d, yyyy' // Sep 20th 2020
const SAME_YEAR_EMAIL_FORMAT = 'LLL dd'
const DIFF_YEAR_EMAIL_FORMAT = 'LLL dd, yyyy'
const DIFF_YEAR_FORMAT = 'MMM d, yyyy h:mm a'
const SAME_YEAR_FORMAT = 'MMM d, h:mm a'
const SAME_WEEK_FORMAT = 'ccc, h:mm a'
const TIME_FORMAT = 'h:mm a'
const SHORT_DATE_TIME_FORMAT = 'M/dd/y h:mm a' // 9/18/20 11:00 AM

const getUserSettingByKey = store.getters['user/getSettingByKey']

export default {
  methods: {
    getLuxonObj,
    convertToUTC,
    convertToUserTimeZone,
    isToday,
    convertToUserTzAndFormat(dateTime, format) {
      return dateTime
        ? convertToUserTimeZone(getLuxonObj(dateTime)).toFormat(format)
        : ''
    },
    convertToLocalTime(dateTime) {
      return dateTime
        ? convertToUserTimeZone(getLuxonObj(dateTime)).toFormat(
            DATE_TIME_FORMAT
          )
        : ''
    },

    convertToLocalTimeShort(time) {
      return time
        ? convertToUserTimeZone(getLuxonObj(time)).toFormat(
            SHORT_DATE_TIME_FORMAT
          )
        : ''
    },

    // By default the function doesn't convert to user TZ
    // Pass convertTz to true to convert to user TZ
    convertToLocalDate(datetime, convertTz = false) {
      return datetime
        ? convertTz
          ? convertToUserTimeZone(getLuxonObj(datetime)).toFormat(DATE_FORMAT)
          : getLuxonObj(datetime).toFormat(DATE_FORMAT)
        : ''
    },

    // TimelineItem dueDate already converted to userTimeZone, only needs formatting
    formatToLocalDate(dateTime) {
      return dateTime ? getLuxonObj(dateTime).toFormat(DATE_FORMAT) : ''
    },

    friendlyTimeFormat(dateTime, isTimeStamp = false) {
      const today = convertToUserTimeZone(DateTime.now())
      if (dateTime) {
        let date = convertToUserTimeZone(getLuxonObj(dateTime, isTimeStamp))
        // Same day
        if (isSame('day')(date, today))
          return 'Today ' + date.toFormat(TIME_FORMAT)
        // Same Week
        else if (isSame('week')(date, today))
          return date.toFormat(SAME_WEEK_FORMAT)
        else if (isSame('year')(date, today))
          return date.toFormat(SAME_YEAR_FORMAT)
        return date.toFormat(DIFF_YEAR_FORMAT)
      }
      return ''
    },

    dayMonthYearFormat(dateTime, isTimeStamp = false) {
      const today = convertToUserTimeZone(DateTime.now())
      if (dateTime) {
        let date = convertToUserTimeZone(getLuxonObj(dateTime, isTimeStamp))
        // Same Year
        if (isSame('year')(date, today))
          return date.toFormat(SAME_YEAR_EMAIL_FORMAT)
        return date.toFormat(DIFF_YEAR_EMAIL_FORMAT)
      }
      return ''
    },

    isOverdue(dueDate, dateOnly = false) {
      if (dueDate) {
        const dueDateDate = convertToUserTimeZone(dueDate, false)
        const timezoneLocalTime = convertToUserTimeZone(DateTime.now())
        if (isBefore(dueDateDate, timezoneLocalTime))
          return dateOnly
            ? Math.abs(dueDateDate.diff(timezoneLocalTime, 'days')) >= 1 // for date only (Step is overdue if due date was atleast a day ago)
            : Math.abs(dueDateDate.diff(timezoneLocalTime, 'minutes')) > 5
        return false
      }
    },

    isInFuture(dueDate) {
      if (dueDate) {
        const dueDateDate = getLuxonObj(dueDate)
        const timezoneLocalTime = convertToUserTimeZone(DateTime.now())
        return (
          isAfter(dueDateDate, timezoneLocalTime) &&
          !isSame('day')(dueDateDate, timezoneLocalTime)
        )
      }
    },

    calculateStepDueDate(stepDueDate, currentStep, isFirstStep) {
      let dueDate
      const startBusinessHours = getUserSettingByKey('startBusinessHours')
      const endBusinessHours = getUserSettingByKey('endBusinessHours')

      // convert to correct time zone via user setting
      dueDate = convertLuxonToBZDaysLuxon(
        convertToUserTimeZone(getLuxonObj(stepDueDate))
      )

      // add interval accounting for weekends - only consider for future projected steps
      if (!isFirstStep) {
        if (currentStep.interval_includes_weekends === 1) {
          // include weekends
          dueDate = dueDate.plus({ minutes: currentStep.interval })
        } else {
          // exclude weekends
          const days = Math.floor(currentStep.interval / 1440)
          const remainingInterval = currentStep.interval % 1440
          if (days !== 0) {
            dueDate = dueDate.plusBusiness({ days: days })
          }
          if (remainingInterval !== 0) {
            dueDate = dueDate.plus({ minutes: remainingInterval })
          }
        }
      }

      // account for business hours - need to consider for all steps
      if (currentStep.execute_after_business_hours === 0) {
        let startBusinessHoursDate = dueDate.set({
          hours: startBusinessHours,
          minutes: 0,
          seconds: 0,
        })

        let endBusinessHoursDate = dueDate.set({
          hours: endBusinessHours,
          minutes: 0,
          seconds: 0,
        })

        if (isBefore(dueDate, startBusinessHoursDate)) {
          // due date is before business hours, set it to start hours
          dueDate = startBusinessHoursDate
        } else if (isBefore(endBusinessHoursDate, dueDate)) {
          // due date is after business hours, set it to start hours and advance one day
          dueDate = startBusinessHoursDate.plus({ days: 1 })
        }
      }

      // set due time if specify time option was used
      if (currentStep.time !== null) {
        const timeHours = Math.floor(currentStep.time / 60)
        const timeMinutes = currentStep.time % 60
        dueDate = dueDate.set({ hours: timeHours })
        dueDate = dueDate.set({ minutes: timeMinutes })

        // if future specify time step is before current due date, move to next day
        if (
          !isFirstStep &&
          isBefore(dueDate, convertToUserTimeZone(getLuxonObj(stepDueDate)))
        ) {
          dueDate = dueDate.plus({ days: 1 })
        }
      }

      if (isHoliday(dueDate) && currentStep.interval_includes_weekends === 0) {
        dueDate = dueDate.plusBusiness({ days: 0 })
      }

      return convertToUTC(dueDate)
    },

    getTimeDistance(targetDateTime, short, fallBackToFriendlyFormat) {
      if (!targetDateTime) return ''
      const nowLocalTime = convertToUserTimeZone(DateTime.now())
      const targetDateLocalTime = convertToUserTimeZone(
        targetDateTime,
        false,
        true
      )
      if (!targetDateLocalTime) return ''
      const durationObj = targetDateLocalTime.diff(nowLocalTime)
      let formattedDistance = formatDistance(durationObj, {
        addSuffix: true,
      })
      if (fallBackToFriendlyFormat) {
        const longerThanThreeDays =
          Math.round(Math.abs(durationObj.values.milliseconds) / 259200000) >= 1
        if (longerThanThreeDays)
          return this.friendlyTimeFormat(targetDateLocalTime)
      }
      if (short) {
        formattedDistance = _replace(formattedDistance, 'about', '')
        formattedDistance = _replace(formattedDistance, 'year', 'yr')
        formattedDistance = _replace(formattedDistance, 'day', 'd')
        formattedDistance = _replace(formattedDistance, 'hour', 'hr')
        formattedDistance = _replace(formattedDistance, 'minute', 'min')
        formattedDistance = _replace(formattedDistance, 'second', 'sec')
      }

      return formattedDistance
    },

    minsToDhm(mins) {
      mins = Number(mins)
      const d = Math.floor(mins / (60 * 24))
      const h = Math.floor((mins % (60 * 24)) / 60)
      const m = Math.floor(mins % 60)

      const dDisplay = d > 0 ? d + 'd ' : ''
      const hDisplay = h > 0 ? h + 'h ' : ''
      const mDisplay = m > 0 ? m + 'm ' : ''
      return dDisplay + hDisplay + mDisplay
    },

    secondsToDhms(seconds) {
      seconds = Number(seconds)
      const d = Math.floor(seconds / (3600 * 24))
      const h = Math.floor((seconds % (3600 * 24)) / 3600)
      const m = Math.floor((seconds % 3600) / 60)
      const s = Math.floor(seconds % 60)

      const dDisplay = d > 0 ? d + 'd ' : ''
      const hDisplay = h > 0 ? h + 'h ' : ''
      const mDisplay = m > 0 ? m + 'm ' : ''
      const sDisplay = s > 0 ? s + 's ' : ''
      return dDisplay + hDisplay + mDisplay + sDisplay
    },

    getDurationString(startDateTime, endDateTime) {
      const start = getLuxonObj(startDateTime)
      const end = getLuxonObj(endDateTime)
      const duration = Math.abs(end.diff(start, 'seconds').toObject().seconds)
      return this.secondsToDhms(duration)
    },

    amPmTime(time24) {
      if (time24) {
        const time = time24.split(':')
        const hours = time[0]
        const minutes = time[1]
        const ampm = hours >= 12 ? 'pm' : 'am'
        const hours12 = hours % 12 || 12
        return `${hours12}:${minutes} ${ampm}`
      } else {
        return ''
      }
    },

    monthDayFormat(date) {
      return getLuxonObj(date).toFormat('LLL d, yyyy')
    },

    timeStampToAmPmTime(timeStamp) {
      const timeObj = getLuxonObj(timeStamp, true)
      return timeObj.toFormat('h:mm a')
    },
  },
}
