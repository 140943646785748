import api from './Api'

export default {
  sendOtp(payload) {
    return api.post('/otp', payload)
  },
  verifyOtp(payload) {
    return api.put('/otp', payload)
  },
}
