import api from './Api'
import { getTablePaginationParams } from './helpers'

const ENDPOINTS = {
  filters: (id = '') => (id ? `/savedFilters/${id}` : `/savedFilters`),
  opts: { showArchived: 'state=archived' },
}

export const SAVED_FILTER_TYPES = Object.freeze({
  PEOPLE: 'people',
  DRIVE: 'drive',
})

/**
 * Get all
 * @export
 * @return {Array} Saved Filters
 */
export function getAllSavedFilters(options = {}) {
  let path = ENDPOINTS.filters()
  let pathParams = []
  // Name search
  const { searchByName, type, isPinned } = options
  if (searchByName) pathParams.push(`name=${searchByName}`)
  if (type) pathParams.push(`type=${type}`)
  if (isPinned !== null && isPinned !== undefined)
    pathParams.push(`is_pinned=${isPinned}`)

  // Pagination
  const pagParams = getTablePaginationParams({ options, itemsPerPage: 2000 })
  if (pagParams) pathParams.push(pagParams)

  if (pathParams.length) path += '?' + pathParams.join('&')
  return api.get(path)
}

/**
 *
 * @export
 * @param {object} filterId - ID of filter to retrieve
 * @return {object} Saved Filter
 */
export function getSavedFilter(filterId) {
  return api.get(ENDPOINTS.filters(filterId))
}

/**
 *
 * @export
 * @param {object} filter filter to store
 * @return {Promise}
 */
export function createSavedFilter(filter) {
  return api.post(ENDPOINTS.filters(), filter)
}

/**
 *
 * @export
 * @param {object} filter filter to update
 * @return {Promise}
 */
export function updateSavedFilter(filter) {
  return api.patch(ENDPOINTS.filters(filter.id), filter)
}

/**
 *
 * @export
 * @param {string} filterId - filter to delete
 * @return {Promise}
 */
export function deleteSavedFilter(filterId) {
  return api.delete(ENDPOINTS.filters(filterId))
}
