import api from './Api'
import { getTablePaginationParams, getResourceRequestQuery } from './helpers'

const ENDPOINTS = {
  dialSessions: (id = '') => (id ? `/dialSessions/${id}` : '/dialSessions'),
  dialSessionCalls: (id = '') =>
    id ? `/dialSessionQueuedCalls/${id}` : '/dialSessionQueuedCalls',
  dialSessionQueuedCall: (id = '') => `dialSessionQueuedCall/${id}`,
  dialSessionNameSuggestion: () => `/dialSessionNameSuggestion`,
  powerDialRates: (countryCode) => `/powerDialRates/${countryCode}`,
  opts: {
    status: (val) => `status=${val}`,
    sessionId: (val) => `dialSessionId=${val}`,
  },
  bulkDialSessionQueuedCalls: 'bulk/dialSessionQueuedCalls',
}

const defaultResourceRequest = [
  'prospect.phoneNumbers',
  'prospect.account',
  'account.phoneNumbers',
  'action.driveStep.drive',
]
export default {
  createPowerDialSession(payload) {
    return api.post(ENDPOINTS.dialSessions(), payload)
  },
  getCurrentPowerDialSession() {
    return api.get(`${ENDPOINTS.dialSessions()}?isActive=1`)
  },
  getCompletedPDSessions(options = {}) {
    let path = `${ENDPOINTS.dialSessions()}?status=completed,cancelled`
    let pathParams = []

    const pagParams = getTablePaginationParams(options)
    if (pagParams) pathParams.push(pagParams)

    if (options.owner) pathParams.push(`owner=${options.owner}`)

    if (pathParams.length) path += '&' + pathParams.join('&')

    return api.get(path)
  },
  getPDSessionsOnHold(options) {
    let path = `${ENDPOINTS.dialSessions()}?isIncomplete=1`
    let pathParams = []

    const pagParams = getTablePaginationParams(options)
    if (pagParams) pathParams.push(pagParams)

    if (options.owner) pathParams.push(`owner=${options.owner}`)

    if (pathParams.length) path += '&' + pathParams.join('&')

    return api.get(path)
  },
  getPowerDialSessionById(sessionId) {
    return api.get(ENDPOINTS.dialSessions(sessionId))
  },
  restoreSession() {},
  updatePowerDialSession(payload, sessionId) {
    return api.put(ENDPOINTS.dialSessions(sessionId), payload)
  },
  addDialSessionQueuedCalls(payload, sessionId) {
    return api.post(
      `${ENDPOINTS.dialSessions(sessionId)}/actions/addDialSessionQueuedCalls`,
      payload
    )
  },
  checkForConnectedCall(sessionId) {
    return api.get(`${ENDPOINTS.dialSessions(sessionId)}/checkForConnectedCall`)
  },
  checkInactivity(sessionId) {
    return api.post(
      `${ENDPOINTS.dialSessions(sessionId)}/actions/checkInactivity`
    )
  },
  pauseDialSession(sessionId) {
    return api.post(`${ENDPOINTS.dialSessions(sessionId)}/actions/pause`)
  },
  holdDialSession(sessionId) {
    return api.post(`${ENDPOINTS.dialSessions(sessionId)}/actions/hold`)
  },
  archiveSession(sessionId) {
    return api.post(`${ENDPOINTS.dialSessions(sessionId)}/actions/archive`)
  },
  resumeDialSession(sessionId) {
    return api.post(`${ENDPOINTS.dialSessions(sessionId)}/actions/resume`)
  },
  endDialSession(sessionId) {
    return api.post(`${ENDPOINTS.dialSessions(sessionId)}/actions/end`)
  },
  splitPDSession(payload) {
    return api.post(
      `${ENDPOINTS.dialSessions(payload.sessionId)}/actions/split`,
      { users: payload.users }
    )
  },

  getNameSuggestion(query) {
    let path = ENDPOINTS.dialSessionNameSuggestion() + '?' + query
    return api.get(path)
  },
  getPowerDialRates(countryCode) {
    return api.get(ENDPOINTS.powerDialRates(countryCode))
  },

  getActiveSessionCalls(options = {}) {
    let path = ENDPOINTS.dialSessionCalls()
    let pathParams = []

    const {
      status,
      dialSessionId,

      resourceRequests,
    } = options

    if (status) pathParams.push(ENDPOINTS.opts.status(status))
    if (dialSessionId) pathParams.push(ENDPOINTS.opts.sessionId(dialSessionId))

    // Pagination
    const pagParams = getTablePaginationParams(options)
    if (pagParams) pathParams.push(pagParams)

    // Request additional resources
    pathParams.push(
      getResourceRequestQuery(resourceRequests || defaultResourceRequest)
    )

    if (pathParams.length) path += '?' + pathParams.join('&')

    return api.get(path)
  },
  updateDialSessionCall(payload, callId) {
    return api.put(ENDPOINTS.dialSessionCalls(callId), payload)
  },
  connectToDialSessionCall(callId) {
    return api.post(
      `${ENDPOINTS.dialSessionCalls(callId)}/actions/connectToCall`
    )
  },
  endDialSessionCall(callId) {
    return api.post(`${ENDPOINTS.dialSessionCalls(callId)}/actions/endCall`)
  },
  vmDropDialSessionCall(callId) {
    return api.post(
      `${ENDPOINTS.dialSessionCalls(callId)}/actions/dropVoicemail`
    )
  },
  removeQueuedCallFromPDSession(queuedCallId) {
    return api.post(
      `${ENDPOINTS.dialSessionQueuedCall(queuedCallId)}/actions/remove`
    )
  },
  retryDialSessionCall(payload) {
    return api.post(
      `${ENDPOINTS.dialSessionQueuedCall(payload.id)}/actions/retry`,
      payload
    )
  },
  markDialSessionCallAsBlacklist(payload) {
    return api.post(
      `${ENDPOINTS.dialSessionQueuedCall(payload.id)}/actions/blacklist`,
      payload
    )
  },

  bulkUpdateCallStatuses(selections, status) {
    const payload = {
      id: selections.join(','),
      status,
    }
    return api.put(ENDPOINTS.bulkDialSessionQueuedCalls, payload)
  },
}
