const state = () => ({
  modalVisible: false,
  modalTitle: '',
  modalText: '',
  dangerModal: false,
  doIfConfirmed: null,
  doIfConfirmedParam: '',
  confirmButtonText: '',
  cancelButtonText: '',
})

const mutations = {
  showModal(state, payload) {
    state.modalVisible = true
    state.modalTitle = payload.title
    state.modalText = payload.text
    state.dangerModal = payload.dangerModal
    state.doIfConfirmed = payload.doIfConfirmed
    state.doIfConfirmedParam = payload.doIfConfirmedParam
    state.confirmButtonText = payload.confirmButtonText || 'Yes'
    state.cancelButtonText = payload.cancelButtonText || 'No'
  },

  hideModal(state) {
    state.modalVisible = false
    state.modalTitle = ''
    state.modalText = ''
  },
}

export default {
  namespaced: true,
  state,
  mutations,
}
