import { getField, updateField } from 'vuex-map-fields'
import DriveStatesApi from '@/services/api/DriveStates'
import BulkProspectExclusionApi from '@/services/api/BulkProspectExclusion'
import { EventBus } from '@/services/event-bus'
import { getTablePaginationParams } from '../../services/api/helpers'

// initial state
const getDefaultState = () => {
  return {
    showStepper: false,
    currentStep: 1,
    totalSteps: 0,
    assignToDriveList: [],
    assignToDriveExclusion: {
      list: [],
      totalCount: 0,
    },
    selectedDrive: [],
    dispositionDriveList: [],
  }
}

const state = getDefaultState()

// getters
const getters = {
  getField,
  currentStep: (state) => state.currentStep,
  driveAssignees: (state) => state.assignToDriveList,
  driveExclusionAssignees: (state) => state.assignToDriveExclusion,
  dispositionDriveList: (state) => state.dispositionDriveList,
  showStepper: (state) => state.showStepper,
}

// actions
const actions = {
  advanceStep({ commit, state }) {
    commit('advanceStep')

    if (state.currentStep > state.totalSteps) {
      // wait until stepper close animation is complete
      EventBus.$emit('actions-fetch')
      EventBus.$emit('crm-fetch-contacts', true)
      EventBus.$emit('people-fetch-contacts', true)
      setTimeout(() => {
        commit('dismissStepper')
        commit('resetState')
      }, 500)
    }
  },

  async assignToDriveStepper(
    { state, dispatch },
    { useLeadProp, schedulePayload, assignToUserId }
  ) {
    return new Promise((resolve, reject) => {
      let driveStates = []
      for (let i = 0, x = state.assignToDriveList.length; i < x; i++) {
        let driveState = {
          drive_id: state.selectedDrive[0].id,
          prospect_id: state.assignToDriveList[i].id,
          external_id: state.assignToDriveList[i].external_id,
        }
        if (schedulePayload) driveState = { ...driveState, ...schedulePayload }
        if (useLeadProp) driveState.is_lead = state.assignToDriveList[i].is_lead
        if (assignToUserId) driveState.assign_to_user_id = assignToUserId
        driveStates.push(driveState)
      }

      dispatch('assignToDrive', driveStates)
        .then((resp) => {
          dispatch('advanceStep')
          resolve(resp)
        })
        .catch((err) => reject(err))
    })
  },

  async assignToDrive({ commit }, driveStates) {
    return new Promise((resolve, reject) => {
      DriveStatesApi.postBulkDriveStates({ requests: driveStates })
        .then((resp) => {
          const errorCount = resp.errorCount
          const successCount = resp.successCount

          if (successCount != 0 && errorCount == 0) {
            commit(
              'snackbar/setSnack',
              {
                snack: 'Successfully added to sequence',
                snackType: 'success',
              },
              { root: true }
            )
          } else if (successCount != 0 && errorCount != 0) {
            commit(
              'snackbar/setSnack',
              {
                snack: `${successCount} added to sequence. ${errorCount} could not be added, they may already be in a sequence or are missing points of contact necessary for the sequence. You can also check if this sequence allows re-enrollment. You can also check if this sequence allows re-enrollment.`,
                snackType: 'warning',
                multiLine: true,
              },
              { root: true }
            )
          } else if (successCount == 0 && errorCount != 0) {
            commit(
              'snackbar/setSnack',
              {
                snack: `${
                  errorCount > 1 ? 'People' : 'Person'
                } could not be added to sequence, they may already be in a sequence or are missing points of contact necessary for the sequence. You can also check if this sequence allows re-enrollment. You can also check if this sequence allows re-enrollment.`,
                snackType: 'error',
                multiLine: true,
              },
              { root: true }
            )
          }
          EventBus.$emit('people-fetch-contacts', true)
          resolve(resp)
        })
        .catch((err) => {
          console.log(err)
          commit(
            'snackbar/setSnack',
            {
              snack: 'There was an error adding to sequence, please try again.',
              snackType: 'error',
            },
            { root: true }
          )
          reject(err)
        })
    })
  },

  async assignToDriveExclusion(
    { commit, dispatch, state, rootGetters },
    {
      accountId,
      queryType,
      reportId,
      exclusionsGetter,
      exclusions,
      limit,
      tableOps,
      body,
    }
  ) {
    let exclusionIds = []
    if (state.dispositionDriveList.length) {
      // Get Prospect IDs of those selected & engaged to exclude from assigning
      exclusionIds = state.dispositionDriveList
        .reduce((prev, curr) => {
          return prev.concat(curr.people)
        }, [])
        .map((person) => person.id)
    }

    // include excluded people for current tab
    const allExclusionIds = exclusionIds.concat(
      exclusions || rootGetters[exclusionsGetter]
    )

    return new Promise((resolve, reject) => {
      BulkProspectExclusionApi.assignToDrive(
        state.selectedDrive[0].id,
        allExclusionIds,
        accountId,
        queryType,
        reportId,
        limit,
        getTablePaginationParams(tableOps, 'orderBy'),
        body
      )
        .then((resp) => {
          const errorCount = resp.errorCount
          const successCount = resp.successCount

          if (successCount != 0 && errorCount == 0) {
            commit(
              'snackbar/setSnack',
              {
                snack: 'Successfully added to sequence',
                snackType: 'success',
              },
              { root: true }
            )
          } else if (successCount != 0 && errorCount != 0) {
            commit(
              'snackbar/setSnack',
              {
                snack: `${successCount} added to sequence. ${errorCount} could not be added, they may already be in a sequence or are missing points of contact necessary for the sequence. You can also check if this sequence allows re-enrollment.`,
                snackType: 'warning',
                multiLine: true,
              },
              { root: true }
            )
          } else if (successCount == 0 && errorCount != 0) {
            commit(
              'snackbar/setSnack',
              {
                snack: `${
                  errorCount > 1 ? 'People' : 'Person'
                } could not be added to sequence, they may already be in a sequence or are missing points of contact necessary for the sequence. You can also check if this sequence allows re-enrollment.`,
                snackType: 'error',
                multiLine: true,
              },
              { root: true }
            )
          }

          dispatch('advanceStep')
          resolve(resp)
        })
        .catch((err) => {
          console.log(err)
          commit(
            'snackbar/setSnack',
            {
              snack: 'There was an error adding to sequence, please try again.',
              snackType: 'error',
            },
            { root: true }
          )
          reject(err)
        })
    })
  },

  async dispositionDriveStepper(
    { state, dispatch },
    { driveId, dispositionId, dispositionNote }
  ) {
    return new Promise((resolve, reject) => {
      let driveStates = []
      const drive = state.dispositionDriveList.find((i) => i.driveId == driveId)

      if (drive) {
        for (let i = 0, x = drive.people.length; i < x; i++) {
          let driveStateId
          if (drive.people[i].driveStates) {
            driveStateId = drive.people[i].driveStates[0].id
          } else driveStateId = drive.people[i].drive_state_id

          driveStates.push({
            drive_state_id: driveStateId,
            disposition_id: dispositionId,
            disposition_note: dispositionNote,
          })
        }

        dispatch('dispositionDrive', driveStates)
          .then((resp) => {
            dispatch('advanceStep')
            resolve(resp)
          })
          .catch((err) => reject(err))
      }
    })
  },

  async dispositionDrive({ commit }, driveStates) {
    return new Promise((resolve, reject) => {
      DriveStatesApi.postBulkDriveDispositions({ requests: driveStates })
        .then((resp) => {
          EventBus.$emit('fetch-pending-action-counts')
          const errorCount = resp.errorCount
          const successCount = resp.successCount

          if (successCount != 0 && errorCount == 0) {
            commit(
              'snackbar/setSnack',
              {
                snack: `Sequence${
                  successCount > 1 ? 's' : ''
                } ended successfully`,
                snackType: 'success',
              },
              { root: true }
            )
          } else if (successCount != 0 && errorCount != 0) {
            commit(
              'snackbar/setSnack',
              {
                snack: `${successCount} sequences ended, ${errorCount} could not be ended.`,
                snackType: 'warning',
                multiLine: true,
              },
              { root: true }
            )
          } else if (successCount == 0 && errorCount != 0) {
            commit(
              'snackbar/setSnack',
              {
                snack: `Failed to end sequence${errorCount > 1 ? 's' : ''}`,
                snackType: 'error',
                multiLine: true,
              },
              { root: true }
            )
          }

          resolve(resp)
        })
        .catch((err) => {
          console.log(err)
          commit(
            'snackbar/setSnack',
            {
              snack: `Failed to end sequences`,
              snackType: 'error',
            },
            { root: true }
          )
          reject(err)
        })
    })
  },

  dismissDriveStepper({ commit }) {
    commit('dismissStepper')
    commit('resetState')
  },

  buildStepperLists({ commit }, { selectedPeople }) {
    if (selectedPeople.length) {
      // used to refresh table once all steps completed
      let stepperDict = selectedPeople.reduce(function (storage, item) {
        // group by drive id if present, `assign` otherwise
        var group = item.driveStates[0]
          ? item.driveStates[0].drive.id
          : 'assign'

        // set `storage` for this instance of group to the outer scope (if not empty) or initialize it
        storage[group] = storage[group] || []

        // add item to its group within `storage`
        storage[group].push(item)

        return storage
      }, {})

      for (const [key, value] of Object.entries(stepperDict)) {
        commit('addTotalStep')
        if (key === 'assign') {
          commit('setAssignToDriveList', value)
        } else {
          commit('pushDispositionDriveList', {
            driveId: key,
            driveName: value[0].driveStates[0].drive.name,
            dispositions: value[0].driveStates[0].drive.dispositions,
            people: value,
          })
        }
      }

      commit('showStepper')
    }
  },

  buildStepperListsExclusion(
    { commit },
    { driveAssignees, driveAssigneesTotal, driveStates }
  ) {
    if (driveAssignees.length) {
      commit('addTotalStep')
      commit('setAssignToDriveExclusion', {
        list: driveAssignees,
        total: driveAssigneesTotal,
      })
    }

    if (driveStates.length) {
      let stepperDict = driveStates.reduce(function (storage, item) {
        // group by drive id and initialize if needed
        const group = item.drive_id
        storage[group] = storage[group] || []

        // add item to its group within `storage`
        storage[group].push(item)
        return storage
      }, {})

      for (const [key, value] of Object.entries(stepperDict)) {
        commit('addTotalStep')
        commit('pushDispositionDriveList', {
          driveId: key,
          driveName: value[0].drive_name,
          dispositions: [],
          people: value,
        })
      }
    }

    commit('showStepper')
  },
}

// mutations
const mutations = {
  updateField,
  showStepper(state) {
    state.showStepper = true
  },
  dismissStepper(state) {
    state.showStepper = false
  },
  advanceStep(state) {
    state.currentStep++
  },
  addTotalStep(state) {
    state.totalSteps++
  },
  setAssignToDriveList(state, list) {
    state.assignToDriveList = list
  },
  setAssignToDriveExclusion(state, { list, total }) {
    if (list) state.assignToDriveExclusion.list = list
    if (total) state.assignToDriveExclusion.totalCount = total
  },
  pushDispositionDriveList(state, item) {
    state.dispositionDriveList.push(item)
  },
  setDispositionDriveList(state, list) {
    state.dispositionDriveList = list
  },
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
