import Vue from 'vue'
import store from '@/store/index'
import PowerDialApi from '@/services/api/PowerDial'
import CallApi from '@/services/api/Calls'
import PhonebookApi from '@/services/api/PhoneNumbers'
import LeadSubscriptionsApi from '@/services/api/LeadSubscriptions'
import {
  dialerServiceActions,
  dialerServiceMutations,
} from '@/services/stateful/dialer/DialerService'
import { EventBus } from '@/services/event-bus'

import {
  convertSecondsToHoursMinutesSeconds,
  renameDuplicates,
} from '@/utils/helpers'
import { convertToUserTimeZone, DATE_FORMAT } from '@/utils/dateHelpers'
import UserSettings from '@/constants/UserSettings'

const _isEmpty = require('lodash/isEmpty')
const _unionBy = require('lodash/unionBy')
const _cloneDeep = require('lodash/cloneDeep')
const getSettingByKey = store.getters['user/getSettingByKey']
const hasPDAutoAnswerDetection = () =>
  getSettingByKey(UserSettings.HAS_PD_AUTO_ANSWER_DETECTION)
const savedPDConfig = () => getSettingByKey(UserSettings.PD_SESSION_CONFIG)
const orgPDConfig = () => getSettingByKey(UserSettings.LOCK_ORG_PD_CONFIG)
const isOrgAdmin = () => store.getters['user/isOrgAdmin']
const orgUsers = () => store.getters['orgUsers/orgUsers']

const getPDCallerIdOption = (session) => {
  if (session.use_local_presence) return 'local-presence'
  if (session.rotate_outbound_caller_ids) return 'rotate-caller-ids'
  return 'pick-caller-id'
}

export const PDItemsPerPage = 50

const state = Vue.observable({
  loadingSession: false,
  loadingInActiveSessions: false,
  activePDSession: null,
  onHoldPDSessions: [],
  onHoldPDSessionsLastPage: 1,
  onHoldPDSessionsTableOps: {
    page: 1,
    itemsPerPage: 25,
  },
  isPowerDialActive: false,
  endSessionLoader: false,
  holdSessionLoader: false,
  resumeSessionLoader: false,
  updatePDSessionLoader: false,

  duplicatePropsectsInPD: [],
  totalDuplicateProspectsCountInPD: 0,
  saveSessionLoader: false,

  statusCounts: [],
  fetchingStatusCounts: false,

  activeSessionQueuedCalls: [],
  activeSessionAttemptedCalls: [],
  activeSessionCompletedCalls: [],
  queuedCallsPagination: { page: 1, itemsPerPage: PDItemsPerPage },
  attemptedCallsPagination: { page: 1, itemsPerPage: PDItemsPerPage },
  completedCallsPagination: { page: 1, itemsPerPage: PDItemsPerPage },

  liveTranscriptionFinals: {},
  liveTranscriptionPartials: {},
  livePDCallDurations: {},
  callDurationInterval: null,

  sessionCallsLoader: false,
  crmReportFirstBatchLoading: false,
  ongoingPowerDialCall: null,

  pdSessionsUsersQuery: '',

  // ongoingPowerDialCall: {
  //   prospect: {
  //     id: '9764b313-1c84-4551-bfa8-e7840d2a2654',
  //     full_name: 'Saad Ahmad',
  //     title: 'Software Developer',
  //   },
  //   answered_at: '2024-03-21T08:28:32.000Z',
  // },

  prospectQuery: '',
  accountQuery: '',
  actionQuery: '',
  assigneeForSessionToCreate: '',

  // Settings
  shouldRememberConfig: true,
  sessionName: '',
  pdPhoneType: [],
  completeActionWhen: 'connected',
  completeActionForFailedCalls: true,
  pdOutboundCallerIdOption: 'local-presence',
  powerDialOutboundCaller: null,
  usePDLocalPresence: true,
  rotatePDCallerIDs: false,
  concurrentCalls: 1,
  maxAttempts: 2,
  minRetryMinutes: 3,
  selectedVoicemail: null,
  immediateConnectOnAnswer: false,
  defaultVmDropDisposition: null,
  defaultFailedDisposition: null,
  defaultCancelledDisposition: null,
  defaultMaxRetriesDisposition: null,

  shouldSubscribeCRMList: false,
  pullSubscriptionLeadsLoader: false,
})

const simpleProps = [
  'loadingSession',
  'loadingInActiveSessions',
  'activePDSession',
  'onHoldPDSessions',
  'onHoldPDSessionsLastPage',
  'onHoldPDSessionsTableOps',
  'saveSessionLoader',
  'duplicatePropsectsInPD',
  'totalDuplicateProspectsCountInPD',
  'isPowerDialActive',
  'prospectQuery',
  'accountQuery',
  'actionQuery',
  'assigneeForSessionToCreate',
  'shouldRememberConfig',
  'sessionName',
  'completeActionWhen',
  'pdPhoneType',
  'completeActionForFailedCalls',
  'pdOutboundCallerIdOption',
  'powerDialOutboundCaller',
  'usePDLocalPresence',
  'rotatePDCallerIDs',
  'concurrentCalls',
  'maxAttempts',
  'minRetryMinutes',
  'immediateConnectOnAnswer',
  'selectedVoicemail',
  'defaultVmDropDisposition',
  'defaultFailedDisposition',
  'defaultCancelledDisposition',
  'defaultMaxRetriesDisposition',
  'endSessionLoader',
  'holdSessionLoader',
  'resumeSessionLoader',
  'updatePDSessionLoader',
  'queuedCallsPagination',
  'attemptedCallsPagination',
  'completedCallsPagination',
  'sessionCallsLoader',
  'ongoingPowerDialCall',
  'statusCounts',
  'fetchingStatusCounts',
  'liveTranscriptionFinals',
  'liveTranscriptionPartials',
  'livePDCallDurations',
  'pdSessionsUsersQuery',
  'crmReportFirstBatchLoading',
  'shouldSubscribeCRMList',
  'pullSubscriptionLeadsLoader',
]

const initPowerDialMutations = () => {
  const setters = {}
  simpleProps.forEach(
    (prop) =>
      (setters[`set${prop[0].toUpperCase()}${prop.slice(1)}`] = (newVal) => {
        state[prop] = newVal
      })
  )
  return setters
}

const initPowerDialGetters = () => {
  const getters = {}
  simpleProps.forEach(
    (prop) =>
      (getters[`get${prop[0].toUpperCase()}${prop.slice(1)}`] = () =>
        state[prop])
  )
  return getters
}

const initComputedProperties = () => {
  const computedProperties = {}
  simpleProps.forEach(
    (prop) =>
      (computedProperties[prop] = {
        get: () =>
          powerDialGetters[`get${prop[0].toUpperCase()}${prop.slice(1)}`](),
        set: (newVal) =>
          powerDialMutations[`set${prop[0].toUpperCase()}${prop.slice(1)}`](
            newVal
          ),
      })
  )
  return computedProperties
}

const powerDialActions = {
  redialOngoingPDCall() {
    const ongoingPowerDialCall = powerDialGetters.getOngoingPowerDialCall()
    EventBus.$emit('minimize-ongoing-pd-call')
    EventBus.$emit('call-now', {
      number: ongoingPowerDialCall.to,
      prospect: ongoingPowerDialCall.prospect,
      fullName: ongoingPowerDialCall.prospect.full_name,
      callImmediately: true,
    })
  },
  checkForConnectedPDCall() {
    return new Promise((resolve, reject) => {
      if (!powerDialGetters.getActivePDSession()) {
        return resolve()
      }
      PowerDialApi.checkForConnectedCall(
        powerDialGetters.getActivePDSession().id
      )
        .then((resp) => resolve(resp))
        .catch((err) => reject(err))
    })
  },
  checkPDInactivity() {
    return new Promise((resolve, reject) => {
      if (!powerDialGetters.getActivePDSession()) {
        return resolve()
      }
      PowerDialApi.checkInactivity(powerDialGetters.getActivePDSession().id)
        .then((resp) => {
          resolve(resp)
        })
        .catch((err) => reject(err))
    })
  },
  unlinkCRMListFromPDSession() {
    return LeadSubscriptionsApi.deleteLeadSubscription(
      powerDialGetters.activePDSessionLeadSubscription()?.id
    ).then(() => location.reload())
  },
  pullCRMSubscriptionLeads() {
    powerDialMutations.setPullSubscriptionLeadsLoader(true)
    return LeadSubscriptionsApi.runSubscription(
      powerDialGetters.activePDSessionLeadSubscription()?.id
    )
      .then(async () => {
        await powerDialActions.fetchActivePDSession()
        await powerDialActions.fetchActiveSessionQueuedCalls(true)
      })
      .finally(() => powerDialMutations.setPullSubscriptionLeadsLoader(false))
  },
  refreshSessionControls() {
    const session = powerDialGetters.getActivePDSession()

    // Caller id
    const callerIdOption = getPDCallerIdOption(session)
    powerDialMutations.setPdOutboundCallerIdOption(callerIdOption)
    powerDialMutations.setUsePDLocalPresence(
      session?.use_local_presence || false
    )
    powerDialMutations.setRotatePDCallerIDs(
      session?.rotate_outbound_caller_ids || false
    )
    powerDialMutations.setPowerDialOutboundCaller(session?.phone?.id || null)

    // Other controls
    powerDialMutations.setSessionName(session?.name || '')
    powerDialMutations.setSelectedVoicemail(session?.voicemailDrop?.id || null)
    powerDialMutations.setConcurrentCalls(session?.concurrent_calls || 1)
    powerDialMutations.setPdPhoneType(session?.allowed_phone_types || [])
  },
  resetPDConfig() {
    const config = orgPDConfig() || savedPDConfig()

    if (!_isEmpty(config)) {
      powerDialMutations.setMaxAttempts(config.max_attempts || 2)
      powerDialMutations.setMinRetryMinutes(config.min_retry_minutes || 3)
      powerDialMutations.setCompleteActionWhen(
        config.complete_action_when || 'connected'
      )
      powerDialMutations.setCompleteActionForFailedCalls(
        config?.complete_action_for_failed_calls != null
          ? config?.complete_action_for_failed_calls
          : true
      )
      powerDialMutations.setPowerDialOutboundCaller(config.phone_id || null)
      powerDialMutations.setDefaultVmDropDisposition(
        config.default_vm_drop_disposition
      )
      powerDialMutations.setDefaultFailedDisposition(
        config.default_failed_disposition
      )
      powerDialMutations.setDefaultCancelledDisposition(
        config.default_cancelled_disposition
      )
      powerDialMutations.setDefaultMaxRetriesDisposition(
        config.default_max_retries_disposition
      )
      powerDialMutations.setImmediateConnectOnAnswer(
        hasPDAutoAnswerDetection() ? !!config.immediate_connect : true
      )
    } else {
      powerDialMutations.setMaxAttempts(2)
      powerDialMutations.setMinRetryMinutes(3)
      powerDialMutations.setCompleteActionWhen('connected')
      powerDialMutations.setCompleteActionForFailedCalls(true)
      powerDialMutations.setDefaultVmDropDisposition(null)
      powerDialMutations.setDefaultFailedDisposition(null)
      powerDialMutations.setDefaultCancelledDisposition(null)
      powerDialMutations.setImmediateConnectOnAnswer(true)
    }
  },

  getPDNameSuggestion(query) {
    return PowerDialApi.getNameSuggestion(query)
  },
  savePowerDialSession(skipDuplicates = null, shouldSetActive = true) {
    powerDialMutations.setSaveSessionLoader(true)
    const payload = powerDialGetters.getPDConfigObject()
    if (skipDuplicates !== null) payload.skip_duplicates = skipDuplicates
    const session = powerDialGetters.getActivePDSession()
    if (session) {
      return new Promise((resolve, reject) => {
        powerDialActions
          .updateActivePowerDialSession(payload, session.id)
          .then((res) => resolve(res))
          .catch((err) => reject(err))
          .finally(() => powerDialMutations.setSaveSessionLoader(false))
      })
    } else {
      payload.prospect_query = powerDialGetters.getProspectQuery()
      payload.account_query = powerDialGetters.getAccountQuery()
      payload.action_query = powerDialGetters.getActionQuery()
      payload.create_lead_subscription =
        powerDialGetters.getShouldSubscribeCRMList()

      payload.owner = powerDialGetters.getAssigneeForSessionToCreate()
      return new Promise((resolve, reject) => {
        powerDialActions
          .createPowerDialSession(payload, shouldSetActive)
          .then((res) => {
            if (!res.hideSnack)
              powerDialActions.setDialSessionCallsSnackbar(res)
            powerDialMutations.setShouldSubscribeCRMList(false)
            resolve(res)
          })
          .catch((err) => reject(err))
          .finally(() => powerDialMutations.setSaveSessionLoader(false))
      })
    }
  },
  createPowerDialSession(payload, shouldSetActive = true) {
    return new Promise((resolve, reject) => {
      PowerDialApi.createPowerDialSession(payload)
        .then((resp) => {
          // TODO: Remove this idea
          if (resp) resp = { ...resp, credits: 10000 }
          if (shouldSetActive) powerDialMutations.setActivePDSession(resp)
          powerDialMutations.resetQueries()
          powerDialMutations.setDuplicatePropsectsInPD([])
          powerDialMutations.setTotalDuplicateProspectsCountInPD(0)

          if (
            payload.prospect_query?.includes('crm_report_id') &&
            resp.meta?.queued_call_queued_count
          ) {
            store.commit('snackbar/setSnack', {
              snack: `Adding ${resp.meta.queued_call_queued_count} calls...`,
              snackType: 'info',
            })
            powerDialMutations.setCrmReportFirstBatchLoading(true)
            resolve({ ...resp, hideSnack: true })
          } else resolve(resp)
        })
        .catch((err) => {
          console.log('err', err.response)
          const { data, code } = err.response.data || {}
          if (code === 'PD.PROSPECTS_HAVE_QUEUED_CALLS') {
            powerDialMutations.setDuplicatePropsectsInPD(data.prospects)
            powerDialMutations.setTotalDuplicateProspectsCountInPD(
              data.total_prospects
            )
          }
          reject(err)
        })
    })
  },
  updateActivePowerDialSession(payload, sessionId) {
    if (!sessionId) sessionId = powerDialGetters.getActivePDSession()?.id
    powerDialMutations.setUpdatePDSessionLoader(true)
    return new Promise((resolve, reject) => {
      PowerDialApi.updatePowerDialSession(payload, sessionId)
        .then((resp) => {
          // TODO: Remove this idea
          if (resp) resp = { ...resp, credits: 10000 }
          powerDialMutations.setActivePDSession(resp)
          powerDialMutations.setDuplicatePropsectsInPD([])
          powerDialMutations.setTotalDuplicateProspectsCountInPD(0)
          resolve(resp)
        })
        .catch((err) => {
          console.log('err', err.response)
          const { data, code } = err.response.data || {}
          if (code === 'PD.PROSPECTS_HAVE_QUEUED_CALLS') {
            powerDialMutations.setDuplicatePropsectsInPD(data.prospects)
            powerDialMutations.setTotalDuplicateProspectsCountInPD(
              data.total_prospects
            )
          }
          reject(err)
        })
        .finally(() => powerDialMutations.setUpdatePDSessionLoader(false))
    })
  },
  updatePDSession(payload, sessionId) {
    powerDialMutations.setUpdatePDSessionLoader(true)
    return new Promise((resolve, reject) => {
      PowerDialApi.updatePowerDialSession(payload, sessionId)
        .then((res) => {
          const sessions = powerDialGetters.getOnHoldPDSessions()
          const index = sessions.findIndex((item) => item.id === sessionId)
          if (index !== -1)
            powerDialMutations.setOnHoldPDSessions([
              ...sessions.slice(0, index),
              { ...sessions[index], name: payload.name },
              ...sessions.slice(index + 1),
            ])
          powerDialMutations.setDuplicatePropsectsInPD([])
          powerDialMutations.setTotalDuplicateProspectsCountInPD(0)
          resolve(res)
        })
        .catch((err) => {
          console.log('err', err.response)
          const { data, code } = err.response.data || {}
          if (code === 'PD.PROSPECTS_HAVE_QUEUED_CALLS') {
            powerDialMutations.setDuplicatePropsectsInPD(data.prospects)
            powerDialMutations.setTotalDuplicateProspectsCountInPD(
              data.total_prospects
            )
          }
          reject(err)
        })
        .finally(() => powerDialMutations.setUpdatePDSessionLoader(false))
    })
  },

  resumePDSession(session) {
    powerDialMutations.setResumeSessionLoader(true)
    return new Promise((resolve, reject) => {
      PowerDialApi.resumeDialSession(session.id)
        .then(() => {
          powerDialActions
            .fetchActivePDSession()
            .then(() => {
              resolve()
            })
            .finally(() => powerDialMutations.setResumeSessionLoader(false))
        })
        .catch((err) => {
          reject(err)
          powerDialMutations.setResumeSessionLoader(false)
        })
    })
  },
  holdActivePDSession() {
    powerDialMutations.setHoldSessionLoader(true)
    powerDialMutations.setPdSessionsUsersQuery('')
    const sessionId = powerDialGetters.getActivePDSession()?.id
    powerDialMutations.setActivePDSession(null)
    return new Promise((resolve, reject) => {
      PowerDialApi.holdDialSession(sessionId)
        .then(() => {
          if (isOrgAdmin() && orgUsers()?.length)
            powerDialMutations.setPdSessionsUsersQuery(
              orgUsers()
                .map((u) => u.id)
                .join(',')
            )
          else powerDialMutations.setPdSessionsUsersQuery('')

          powerDialActions.fetchPDSessionsOnHold().then(() => {
            resolve()
          })
        })
        .catch((err) => reject(err))
        .finally(() => powerDialMutations.setHoldSessionLoader(false))
    })
  },
  endPowerDialSession(sessionToDelete = null) {
    powerDialMutations.setEndSessionLoader(true)
    dialerServiceMutations.setCurrentDialSessionId(null)
    const session = sessionToDelete || powerDialGetters.getActivePDSession()
    return new Promise((resolve, reject) => {
      PowerDialApi.endDialSession(session.id)
        .then((resp) => {
          powerDialMutations.resetState()
          resolve(resp)
        })
        .catch((err) => reject(err))
        .finally(() => powerDialMutations.setEndSessionLoader(false))
    })
  },

  addCallsToActiveDialSession(skipDuplicates = null) {
    const session = powerDialGetters.getActivePDSession()
    const payload = {
      prospect_query: powerDialGetters.getProspectQuery(),
      account_query: powerDialGetters.getAccountQuery(),
      action_query: powerDialGetters.getActionQuery(),
    }
    if (skipDuplicates !== null) payload.skip_duplicates = skipDuplicates
    return new Promise((resolve, reject) => {
      PowerDialApi.addDialSessionQueuedCalls(payload, session.id)
        .then((res) => {
          powerDialMutations.setProspectQuery('')
          powerDialMutations.setAccountQuery('')
          powerDialMutations.setActionQuery('')
          powerDialMutations.setDuplicatePropsectsInPD([])
          powerDialMutations.setTotalDuplicateProspectsCountInPD(0)
          powerDialActions.setDialSessionCallsSnackbar(res)
          resolve(res)
        })
        .catch((err) => {
          console.log('err', err.response)
          const { data, code } = err.response.data || {}
          if (code === 'PD.PROSPECTS_HAVE_QUEUED_CALLS') {
            powerDialMutations.setDuplicatePropsectsInPD(data.prospects)
            powerDialMutations.setTotalDuplicateProspectsCountInPD(
              data.total_prospects
            )
          }
          reject(err)
        })
    })
  },

  setDialSessionCallsSnackbar(res) {
    const { queued_call_success_count, queued_call_error_count } =
      res.meta || {}
    const successMessage = `${queued_call_success_count} contacts added. `
    const errorMessage = queued_call_error_count
      ? `${queued_call_error_count} could not be added. Please make sure these contacts have phone numbers and are in a prospect status that is eligible for power dialing.`
      : ''
    store.commit(
      'snackbar/setSnack',
      {
        snack: successMessage + errorMessage,
        snackType: !queued_call_error_count ? 'success' : 'warning',
        multiLine: !!queued_call_error_count,
      },
      { root: true }
    )
  },

  addCallsToDialSession(sessionId, skipDuplicates = null) {
    const payload = {
      prospect_query: powerDialGetters.getProspectQuery(),
      account_query: powerDialGetters.getAccountQuery(),
      action_query: powerDialGetters.getActionQuery(),
    }
    if (skipDuplicates !== null) payload.skip_duplicates = skipDuplicates
    return new Promise((resolve, reject) => {
      PowerDialApi.addDialSessionQueuedCalls(payload, sessionId)
        .then((res) => {
          powerDialMutations.setDuplicatePropsectsInPD([])
          powerDialMutations.setTotalDuplicateProspectsCountInPD(0)
          powerDialActions.setDialSessionCallsSnackbar(res)
          resolve(res)
        })
        .catch((err) => {
          console.log('err', err.response)
          const { data, code } = err.response.data || {}
          if (code === 'PD.PROSPECTS_HAVE_QUEUED_CALLS') {
            powerDialMutations.setDuplicatePropsectsInPD(data.prospects)
            powerDialMutations.setTotalDuplicateProspectsCountInPD(
              data.total_prospects
            )
          }
          reject(err)
        })
    })
  },

  fetchAndSetDialSession(sessionId, indexToSet) {
    PowerDialApi.getPowerDialSessionById(sessionId).then((res) => {
      console.log('fetchAndSetDialSession', res)
      powerDialMutations.setOnHoldPDSessions([
        ...powerDialGetters.getOnHoldPDSessions()?.slice(0, indexToSet),
        res,
        ...powerDialGetters.getOnHoldPDSessions().slice(indexToSet + 1),
      ])
    })
  },

  fetchStatusCounts() {
    const session = powerDialGetters.getActivePDSession()
    if (!session) return new Promise((resolve) => resolve())

    powerDialMutations.setFetchingStatusCounts(true)
    return new Promise((resolve, reject) => {
      PowerDialApi.getActiveSessionCalls({
        dialSessionId: session?.id,
        ...{ page: 1, itemsPerPage: 1 },
      })
        .then((res) => {
          powerDialMutations.setStatusCounts(res.status_counts || [])

          const inProgressCalls = res.status_counts.filter((i) =>
            ['queued', 'in_progress', 'dialing'].includes(i.status)
          )

          const queuedCallsCount = inProgressCalls.reduce(
            (a, i) => a + i.count,
            0
          )

          if (res.pagination) {
            const currentPagination =
              powerDialGetters.getQueuedCallsPagination()
            powerDialMutations.setQueuedCallsPagination({
              ...currentPagination,
              total: queuedCallsCount,
              lastPage: Math.ceil(
                queuedCallsCount / currentPagination.itemsPerPage
              ),
            })
          }

          if (
            !powerDialGetters.hasQueuedCalls() &&
            powerDialGetters.getIsPowerDialActive()
          )
            powerDialMutations.setIsPowerDialActive(false)
          resolve(res)
        })
        .catch((err) => reject(err))
        .finally(() => powerDialMutations.setFetchingStatusCounts(false))
    })
  },

  fetchActiveSessionQueuedCalls(
    reset = false,
    hideLoader = false,
    resetCrmFirstBatchLoader = false
  ) {
    const session = powerDialGetters.getActivePDSession()
    if (!session) return new Promise((resolve) => resolve())

    if (!hideLoader) powerDialMutations.setSessionCallsLoader(true)
    const pagination = powerDialGetters.getQueuedCallsPagination()
    return new Promise((resolve, reject) => {
      PowerDialApi.getActiveSessionCalls({
        status: 'queued,in_progress,dialing',
        dialSessionId: session?.id,
        ...pagination,
      })
        .then((res) => {
          powerDialMutations.setStatusCounts(res.status_counts || [])
          powerDialMutations.setQueuedCallsPagination(res.pagination)
          let totalCalls = []
          if (resetCrmFirstBatchLoader)
            powerDialMutations.setCrmReportFirstBatchLoading(false)

          if (reset) totalCalls = res?.data || []
          else
            totalCalls = _unionBy(
              powerDialGetters.getActiveSessionQueuedCalls(),
              res?.data || [],
              'id'
            )
          powerDialMutations.setActiveSessionQueuedCalls(totalCalls)
          resolve(res)
        })
        .catch((err) => reject(err))
        .finally(() => powerDialMutations.setSessionCallsLoader(false))
    })
  },
  fetchActiveSessionAttemptedCalls(reset = false) {
    const session = powerDialGetters.getActivePDSession()
    if (!session) return new Promise((resolve) => resolve())

    powerDialMutations.setSessionCallsLoader(true)
    const pagination = powerDialGetters.getAttemptedCallsPagination()
    return new Promise((resolve, reject) => {
      PowerDialApi.getActiveSessionCalls({
        status: 'attempted,failed',
        dialSessionId: session?.id,
        ...pagination,
      })
        .then((res) => {
          powerDialMutations.setStatusCounts(res.status_counts || [])
          powerDialMutations.setAttemptedCallsPagination(res.pagination)
          let totalCalls = []
          if (reset) totalCalls = res?.data || []
          else
            totalCalls = _unionBy(
              powerDialGetters.getActiveSessionAttemptedCalls(),
              res?.data || [],
              'id'
            )
          powerDialMutations.setActiveSessionAttemptedCalls(totalCalls)
          resolve(res)
        })
        .catch((err) => reject(err))
        .finally(() => powerDialMutations.setSessionCallsLoader(false))
    })
  },
  fetchActiveSessionCompletedCalls(reset = false) {
    const session = powerDialGetters.getActivePDSession()
    if (!session) return new Promise((resolve) => resolve())

    powerDialMutations.setSessionCallsLoader(true)
    const pagination = powerDialGetters.getCompletedCallsPagination()
    return new Promise((resolve, reject) => {
      PowerDialApi.getActiveSessionCalls({
        status: 'completed,cancelled',
        dialSessionId: session?.id,
        ...pagination,
      })
        .then((res) => {
          powerDialMutations.setStatusCounts(res.status_counts || [])
          powerDialMutations.setCompletedCallsPagination(res.pagination)
          let totalCalls = []
          if (reset) totalCalls = res?.data || []
          else
            totalCalls = _unionBy(
              powerDialGetters.getActiveSessionCompletedCalls(),
              res?.data || [],
              'id'
            )
          powerDialMutations.setActiveSessionCompletedCalls(totalCalls)
          resolve(res)
        })
        .catch((err) => reject(err))
        .finally(() => powerDialMutations.setSessionCallsLoader(false))
    })
  },
  fetchPDSessionsOnHold(append = false) {
    // reset pagination
    if (!append) {
      powerDialMutations.setLoadingInActiveSessions(true)
      powerDialMutations.setOnHoldPDSessionsTableOps({
        page: 1,
        itemsPerPage: 25,
      })
    }

    return new Promise((resolve, reject) => {
      PowerDialApi.getPDSessionsOnHold({
        owner: powerDialGetters.getPdSessionsUsersQuery(),
        ...powerDialGetters.getOnHoldPDSessionsTableOps(),
      })
        .then((res) => {
          let sessions = res.data.map((item) => ({
            ...item,
            name:
              item.name ||
              convertToUserTimeZone(item.created_at, true, false, DATE_FORMAT),
          }))
          if (append) {
            sessions = [...powerDialGetters.getOnHoldPDSessions(), ...sessions]
          }
          powerDialMutations.setOnHoldPDSessionsLastPage(
            res.pagination.lastPage
          )
          powerDialMutations.setOnHoldPDSessions(
            renameDuplicates(sessions, 'name')
          )
          resolve(res)
        })
        .catch((err) => reject(err))
        .finally(() => powerDialMutations.setLoadingInActiveSessions(false))
    })
  },
  fetchActivePDSession() {
    powerDialMutations.setLoadingSession(true)
    return new Promise((resolve, reject) => {
      PowerDialApi.getCurrentPowerDialSession()
        .then((res) => {
          if (res.data.length) {
            let session = res.data[0]
            if (session) {
              session = {
                ...session,
                credits: 10000, // TODO: Remove this idea
                name:
                  session.name ||
                  convertToUserTimeZone(
                    session.created_at,
                    true,
                    false,
                    DATE_FORMAT
                  ),
              }
            }
            resolve(session)
            powerDialMutations.setActivePDSession(session)
            powerDialActions.refreshSessionControls()
            resolve(res)
          }
        })
        .catch((err) => reject(err))
        .finally(() => powerDialMutations.setLoadingSession(false))
    })
  },

  updatePDCallStatus(id, status) {
    return new Promise((resolve, reject) =>
      PowerDialApi.updateDialSessionCall({ status }, id)
        .then((res) => {
          resolve(res)
        })
        .catch((err) => reject(err))
    )
  },

  connectToPDCall(call) {
    if (!call) return

    powerDialMutations.setOngoingPowerDialCall(call)
    return new Promise((resolve, reject) =>
      PowerDialApi.connectToDialSessionCall(call.id)
        .then((res) => {
          powerDialMutations.setIsPowerDialActive(false)
          resolve(res)
        })
        .catch((err) => reject(err))
    )
  },
  endPDCall(call) {
    let callObj = call || powerDialGetters.getOngoingPowerDialCall()

    if (!call) {
      return new Promise((resolve, reject) => {
        dialerServiceActions
          .disconnectCall()
          .then(() => {
            powerDialMutations.setOngoingPowerDialCall({
              ...callObj,
              hungup: true,
            })
            resolve()
          })
          .catch((err) => reject(err))
      })
    }

    return new Promise((resolve, reject) =>
      PowerDialApi.endDialSessionCall(callObj.id)
        .then((res) => {
          powerDialActions.fetchStatusCounts()
          resolve(res)
        })
        .catch((err) => reject(err))
    )
  },
  vmDropPDCall(call) {
    let callObj = call || powerDialGetters.getOngoingPowerDialCall()

    return new Promise((resolve, reject) =>
      PowerDialApi.vmDropDialSessionCall(callObj.id)
        .then((res) => {
          if (!call) {
            EventBus.$emit('dropping-vm-on-call', callObj.id)
            powerDialMutations.setOngoingPowerDialCall(null)
          } else powerDialActions.fetchStatusCounts()
          resolve(res)
        })
        .catch((err) => reject(err))
    )
  },
  removePDSessionCall(queuedCallId) {
    return PowerDialApi.removeQueuedCallFromPDSession(
      queuedCallId || powerDialGetters.getOngoingPowerDialCall()?.id
    ).then(() => {
      this.$store.commit('snackbar/setSnack', {
        snack: 'Call removed from dial session',
        snackType: 'success',
      })
      powerDialActions.fetchActiveSessionQueuedCalls(true)
    })
  },
  markAsWrongNumber(prospectPhones) {
    const to = powerDialGetters.getOngoingPowerDialCall().to
    const phoneObject = prospectPhones.find((phone) => phone.number === to)

    return PhonebookApi.markAsWrongNumber(phoneObject.id).then(() => {
      this.$store.commit('snackbar/setSnack', {
        snack: `Phone number ${phoneObject.number} marked as wrong number`,
        snackType: 'success',
      })
      powerDialActions.fetchActiveSessionQueuedCalls(true)
    })
  },
  markAsBlacklisted(prospectPhones) {
    const to = powerDialGetters.getOngoingPowerDialCall().to
    const phoneObject = prospectPhones.find((phone) => phone.number === to)

    return PhonebookApi.blacklistNumber(phoneObject).then(() => {
      this.$store.commit('snackbar/setSnack', {
        snack: `Phone number ${phoneObject.number} blacklisted`,
        snackType: 'success',
      })
      powerDialActions.fetchActiveSessionQueuedCalls(true)
    })
  },

  updateOngoingPDCall(payload) {
    let call = powerDialGetters.getOngoingPowerDialCall()
    if (!call) return

    return new Promise((resolve, reject) =>
      CallApi.updateCall({
        callId: call.callId,
        call: payload,
      })
        .then((res) => resolve(res))
        .catch((err) => reject(err))
    )
  },

  bulkUpdatePDCallStatus(selections, status) {
    return new Promise((resolve, reject) =>
      PowerDialApi.bulkUpdateCallStatuses(selections, status)
        .then((res) => resolve(res))
        .catch((err) => reject(err))
    )
  },
}

const powerDialComputed = {
  ...initComputedProperties(),
  activeSessionQueuedCalls: {
    get: () => powerDialGetters.getActiveSessionQueuedCalls(),
    set: (newVal) => powerDialMutations.setActiveSessionQueuedCalls(newVal),
  },
  activeSessionAttemptedCalls: {
    get: () => powerDialGetters.getActiveSessionAttemptedCalls(),
    set: (newVal) => powerDialMutations.setActiveSessionAttemptedCalls(newVal),
  },
  activeSessionCompletedCalls: {
    get: () => powerDialGetters.getActiveSessionCompletedCalls(),
    set: (newVal) => powerDialMutations.setActiveSessionCompletedCalls(newVal),
  },
}
const powerDialGetters = {
  ...initPowerDialGetters(),
  autoTimezone: () => state.activePDSession?.use_auto_timezone,
  autoTimezoneStart: () => state.activePDSession?.auto_timezone_start_hour,
  autoTimezoneEnd: () => state.activePDSession?.auto_timezone_end_hour,
  currentCallingTimezone: () => state.activePDSession?.current_calling_timezone,
  noPDSessions: () => !state.onHoldPDSessions.length && !state.activePDSession,
  allPDSessions: () =>
    _unionBy(
      state.activePDSession ? [state.activePDSession] : [],
      state.onHoldPDSessions || [],
      'id'
    ),
  getPDConfigObject: () => {
    const outboundCallerIdOpt = powerDialGetters.getPdOutboundCallerIdOption()
    return {
      complete_action_when: powerDialGetters.getCompleteActionWhen(),
      allowed_phone_types: powerDialGetters.getPdPhoneType(),
      complete_action_for_failed_calls:
        powerDialGetters.getCompleteActionForFailedCalls(),
      concurrent_calls: powerDialGetters.getConcurrentCalls(),
      use_local_presence: outboundCallerIdOpt === 'local-presence',
      rotate_outbound_caller_ids: outboundCallerIdOpt === 'rotate-caller-ids',
      phone_id: powerDialGetters.getPowerDialOutboundCaller(),
      max_attempts: powerDialGetters.getMaxAttempts(),
      name: powerDialGetters.getSessionName(),
      min_retry_minutes: powerDialGetters.getMinRetryMinutes(),
      immediate_connect: hasPDAutoAnswerDetection()
        ? powerDialGetters.getImmediateConnectOnAnswer()
        : true,
      voicemail_drop_id: powerDialGetters.getSelectedVoicemail(),
      default_vm_drop_disposition:
        powerDialGetters.getDefaultVmDropDisposition(),
      default_failed_disposition:
        powerDialGetters.getDefaultFailedDisposition(),
      default_cancelled_disposition:
        powerDialGetters.getDefaultCancelledDisposition(),
      default_max_retries_disposition:
        powerDialGetters.getDefaultMaxRetriesDisposition(),
    }
  },
  getActiveSessionQueuedCalls: () => state.activeSessionQueuedCalls,
  getActiveSessionAttemptedCalls: () => state.activeSessionAttemptedCalls,
  getActiveSessionCompletedCalls: () => state.activeSessionCompletedCalls,
  hasQueuedCalls: () => {
    let count = 0
    let queuedStatuses = ['queued', 'in_progress', 'dialing']
    queuedStatuses.forEach((key) => {
      const countPerKey =
        state.statusCounts.find((item) => item.status === key)?.count || 0
      count += countPerKey
    })
    return !!count
  },
  livePDCalls: () =>
    state.activeSessionQueuedCalls.filter((call) =>
      ['in_progress', 'dialing'].includes(call.status)
    ) || [],
  lowPDCredits: () =>
    (state.activePDSession.credits || 0) >= 50 &&
    (state.activePDSession.credits || 0) <= 200,
  insufficientPDCredits: () => (state.activePDSession.credits || 0) <= 50,
  activePDSessionLeadSubscription: () =>
    state.activePDSession?.leadSubscriptions?.[0] || null,
}

const powerDialMutations = {
  ...initPowerDialMutations(),
  setActiveSessionQueuedCall(call, merge = false) {
    const index = state.activeSessionQueuedCalls.findIndex(
      (item) => item.id === call.id
    )
    const merged = { ...state.activeSessionQueuedCalls[index], ...call }
    state.activeSessionQueuedCalls = _cloneDeep([
      ...state.activeSessionQueuedCalls.slice(0, index),
      merge ? merged : call,
      ...state.activeSessionQueuedCalls.slice(index + 1),
    ])
  },
  setQueuedCallsPagination(pagination) {
    state.queuedCallsPagination = {
      ...pagination,
      itemsPerPage: pagination.perPage || pagination.itemsPerPage,
    }
  },
  setAttemptedCallsPagination(pagination) {
    state.attemptedCallsPagination = {
      ...pagination,
      itemsPerPage: pagination.perPage || pagination.itemsPerPage,
    }
  },
  setCompletedCallsPagination(pagination) {
    state.completedCallsPagination = {
      ...pagination,
      itemsPerPage: pagination.perPage || pagination.itemsPerPage,
    }
  },
  setActivePDSession(session) {
    if (session) {
      state.activePDSession = {
        ...session,
        credits: 10000, // TODO: Remove this idea
        name:
          session.name ||
          convertToUserTimeZone(session.created_at, true, false, DATE_FORMAT),
      }
    } else state.activePDSession = null
  },
  setOnHoldPDSessions(sessions) {
    state.onHoldPDSessions = _cloneDeep(sessions)
  },
  setActiveSessionQueuedCalls(calls) {
    state.activeSessionQueuedCalls = _cloneDeep(calls)
  },
  setActiveSessionAttemptedCalls(calls) {
    state.activeSessionAttemptedCalls = _cloneDeep(calls)
  },
  setActiveSessionCompletedCalls(calls) {
    state.activeSessionCompletedCalls = _cloneDeep(calls)
  },
  removeActiveCall(id) {
    state.activeSessionQueuedCalls = _cloneDeep(
      state.activeSessionQueuedCalls.filter((call) => call.id !== id)
    )
    if (id !== state.ongoingPowerDialCall?.id) {
      powerDialMutations.removeCallTranscription(id)
    }
  },
  removeCallTranscription(id) {
    delete state.liveTranscriptionFinals[id]
    delete state.liveTranscriptionPartials[id]
  },
  removeActiveCalls(ids) {
    state.activeSessionQueuedCalls = _cloneDeep(
      state.activeSessionQueuedCalls.filter((call) => !ids.includes(call.id))
    )
    // Remove transcriptions if not ongoing call
    ids.forEach((id) => {
      if (id !== state.ongoingPowerDialCall?.id)
        powerDialMutations.removeCallTranscription(id)
    })
  },
  removeAttemptedCall(id) {
    state.activeSessionAttemptedCalls =
      state.activeSessionAttemptedCalls.filter((call) => call.id !== id)
    delete state.livePDCallDurations[id]
  },
  removeAttemptedCalls(ids) {
    state.activeSessionAttemptedCalls =
      state.activeSessionAttemptedCalls.filter((call) => !ids.includes(call.id))
  },
  removeCompletedCall(id) {
    state.activeSessionCompletedCalls =
      state.activeSessionCompletedCalls.filter((call) => call.id !== id)
    delete state.livePDCallDurations[id]
  },
  removeCompletedCalls(ids) {
    state.activeSessionCompletedCalls =
      state.activeSessionCompletedCalls.filter((call) => !ids.includes(call.id))
  },
  startCallDurationCalculation() {
    if (state.callDurationInterval) clearInterval(state.callDurationInterval)
    state.callDurationInterval = setInterval(() => {
      state.activeSessionQueuedCalls.forEach((call) => {
        if (!['in_progress', 'dialing'].includes(call.status))
          delete state.livePDCallDurations[call.id]
        else if (call.started_at)
          state.livePDCallDurations = {
            ...state.livePDCallDurations,
            [call.id]: convertSecondsToHoursMinutesSeconds(
              (Date.now() - new Date(call.started_at)) / 1000
            ),
          }
      })
    }, 1000)
  },
  stopCallDurationCalculation() {
    clearInterval(state.callDurationInterval)
    state.livePDCallDurations = {}
  },
  resetQueries() {
    powerDialMutations.setProspectQuery('')
    powerDialMutations.setAccountQuery('')
    powerDialMutations.setActionQuery('')
  },
  resetTableData() {
    state.activeSessionQueuedCalls = []
    state.activeSessionAttemptedCalls = []
    state.activeSessionCompletedCalls = []
    state.queuedCallsPagination = { page: 1, itemsPerPage: PDItemsPerPage }
    state.attemptedCallsPagination = { page: 1, itemsPerPage: PDItemsPerPage }
    state.completedCallsPagination = { page: 1, itemsPerPage: PDItemsPerPage }
  },
  resetState() {
    state.activePDSession = null
    state.isPowerDialActive = false
    powerDialMutations.resetTableData()
    state.sessionCallsLoader = false

    state.liveTranscriptionFinals = {}
    state.liveTranscriptionPartials = {}
    state.livePDCallDurations = {}

    state.ongoingPowerDialCall = null

    state.prospectQuery = ''
    state.accountQuery = ''
    state.actionQuery = ''

    state.completeActionWhen = 'connected'
    state.completeActionForFailedCalls = true
    state.powerDialOutboundCaller = null
    state.usePDLocalPresence = false
    state.concurrentCalls = 1
    state.maxAttempts = 2
    state.minRetryMinutes = 3
    state.selectedVoicemail = null
    state.defaultVmDropDisposition = null
    state.defaultFailedDisposition = null
    state.defaultCancelledDisposition = null
    state.defaultMaxRetriesDisposition = null
  },
}

const getTranscription = (queuedCallId, truncate = true) => {
  const finalsForCall = state.liveTranscriptionFinals[queuedCallId] || []
  const partialForCall = state.liveTranscriptionPartials[queuedCallId] || ''

  let transcription =
    finalsForCall.join(' ') + (finalsForCall.length ? ' ' : '') + partialForCall
  if (!truncate) return transcription
  if (transcription.length > 150)
    transcription = '...' + transcription.slice(transcription.length - 150)

  return transcription
}

const getLivePDCallDuration = (id) => state.livePDCallDurations[id]

export {
  powerDialActions,
  powerDialComputed,
  powerDialGetters,
  powerDialMutations,
  getTranscription,
  getLivePDCallDuration,
}
