import ProspectsApi from '@/services/api/Prospects'

// initial state
const state = () => ({
  status: '',
  contact: {},
})

// getters
const getters = {
  contact: (state) =>
    state.contact && Object.keys(state.contact).length !== 0
      ? {
          ...state.contact,
          full_name: state.contact.full_name
            ? state.contact.full_name
            : state.contact.first_name || state.contact.last_name
            ? `${state.contact.first_name} ${state.contact.last_name}`.trim()
            : '(unknown)',
        }
      : {},
  contactLoading: (state) => state.status === 'loading',
  contactOptedOutEmails: (state) =>
    state.contact?.emails_opt_status === 'unsubscribed',
}

// actions
const actions = {
  async fetchContact({ commit }, id) {
    return new Promise((resolve, reject) => {
      commit('request')
      ProspectsApi.getProspectById(id)
        .then((resp) => {
          commit('success')
          commit('setContact', resp)
          resolve(resp)
        })
        .catch((err) => {
          commit('error')
          reject(err)
        })
    })
  },

  async updateContact({ commit, state }, { prospectId, prospect, hideLoader }) {
    return new Promise((resolve, reject) => {
      if (!hideLoader) commit('request')
      ProspectsApi.updateProspect({
        prospectId: prospectId,
        prospect: prospect,
      })
        .then((resp) => {
          commit('success')
          commit('setContact', {
            ...resp,
            user: resp.user || state.contact.user,
          })
          commit(
            'snackbar/setSnack',
            {
              snack: 'Person updated successfully',
              snackType: 'success',
            },
            { root: true }
          )
          resolve(resp)
        })
        .catch((err) => {
          console.log(err)
          commit(
            'snackbar/setSnack',
            {
              snack: 'Error updating person',
              snackType: 'error',
            },
            { root: true }
          )
          reject(err)
        })
    })
  },

  async toggleAutoScheduleActions({ state, commit }) {
    return new Promise((resolve, reject) => {
      ProspectsApi.updateAutoScheduleActions(state.contact.id, {
        auto_schedule: !state.contact.auto_schedule,
      })
        .then((resp) => {
          commit(
            'snackbar/setSnack',
            {
              snack: !state.contact.auto_schedule
                ? 'Auto schedule actions enabled'
                : 'Auto schedule actions disabled',
              snackType: 'success',
            },
            { root: true }
          )
          commit('toggleAutoSchedule')
          resolve(resp)
        })
        .catch((err) => {
          commit(
            'snackbar/setSnack',
            {
              snack: 'Error updating auto schedule status',
              snackType: 'error',
            },
            { root: true }
          )
          reject(err)
        })
    })
  },
}

// mutations
const mutations = {
  setContact(state, contact) {
    state.contact = contact
  },
  request(state) {
    state.contact = {}
    state.status = 'loading'
  },
  success(state) {
    state.status = 'success'
  },
  error(state) {
    state.status = 'error'
  },
  toggleAutoSchedule(state) {
    state.contact.auto_schedule = !state.contact.auto_schedule
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
