import Vue from 'vue'
import store from '@/store/index'
import router from '@/router'
import { EventBus } from '@/services/event-bus'
import { UserSettings } from '@/constants'
import { plivoDriver } from './drivers'
import {
  sendSnackMessage,
  isSameNumber,
  getFullName,
  convertSecondsToHoursMinutesSeconds,
} from '@/utils/helpers'
import CallApi from '@/services/api/Calls'
import CrmApi from '@/services/api/Crm'
import UtilsAPi from '@/services/api/Utils'
import FeatureFlags from '@/services/feature-flags/FeatureFlags'
import hubspotPlugin from '@/services/stateful/dialer/plugins/hubspot'
import salesforcePlugin from '@/services/stateful/dialer/plugins/salesforce'
import pipedrivePlugin from '@/services/stateful/dialer/plugins/pipedrive'
import { markActionComplete } from '@/services/stateful/QuickActionService'

import { getLSItem, setLSItem } from '@/utils/localStorage'

const phonesLoading = () => store.getters['phone/loading']
const user = () => store.getters['user/user']
const isOrgAdmin = () => store.getters['user/isOrgAdmin']
const getSettingByKey = store.getters['user/getSettingByKey']
const _cloneDeep = require('lodash/cloneDeep')
const _sortBy = require('lodash/sortBy')
const allPhones = () => {
  const phones = store.getters['phone/allPhones']
  if (!phones || !phones.length) return []
  if (!FeatureFlags.isPlivoDriverEnabled()) {
    return phones.filter((phone) => phone.provider !== 'plivo')
  }
  return phones
}
const isGHLWidget = () => store.getters['user/isGHLWidget']
const outboundActivatedPhones = () =>
  allPhones().filter((phone) => !phone.deactivated)
const selectedOutboundSMSPhone = () =>
  store.getters['phone/selectedOutboundSMSPhone']

/* -------------------------------------------------------------------------- */
/*                                 Setup State                                */
/* -------------------------------------------------------------------------- */
const privateStateProps = {
  call: null,
  incomingCalls: [],
  currentSuggestionId: null,
  device: null,
  isRinging: false,
  queuedTokenRefresh: false,
  firstLoad: true,
  onReadyToCall: null,
  callTimer: null,
}

const publicStateProps = {
  activeCallDurationSecs: 0,
  callDurationInterval: null,
  activeCallAnswered: false,
  callDisposition: null,
  callDispositionGroup: null,
  currentActionId: null,
  currentDialSessionId: null,
  currentPhoneRecord: {
    account: null,
    externalId: null,
    externalObjectType: null,
    fullName: '', // Optional
    id: null,
    number: null, // Optional
    prospect: null,
    tags: [],
    workEmail: '', // Optional
  },
  dialerPermissionsBlocked: false,
  secondParticipant: null,
  secondParticipantRinging: false,
  secondParticipantConnected: false,
  hungUpNumberInMpc: null,
  redialNumberInMpc: null,
  mergeCallLoader: false,
  dialerSearchInput: '',
  localPresenceOutboundCaller: null,
  note: '',
  provider: null,
  selectedOutboundCallerObj: null,
  selectedOutboundCaller: null,
  subject: '',
  machineDetected: false,
  transferredTo: null,
  transferInProgress: false,
  selectedMessageThread: null,
  hasUnreadTextMessage: false,
  hasMissedCall: false,
  feedbackIssues: [],
  feedbackRating: 0,
  feedbackNote: '',
  feedbackReportedSpam: false,
  audioTestCallInProgress: false,
  audioTestResultsLoading: false,
  audioTestResults: false,

  // CRM PLUGINS
  isHubspotDialer: false,
}

const publicReadOnlyStateProps = {
  callBackNumberLocalPresenceStatus: null,
  callCompleted: false,
  callConnectivityIssues: [],
  callId: null,
  callRecordingComplianceStatus: null,
  deviceLog: '',
  forceRecordOption: '',
  isDialerOpen: false,
  isMuted: false,
  isRecording: false,
  onPhone: false,
  volume: 0,
  availableDevices: null,
  selectedMicrophone: null,
  selectedSpeaker: null,
  callSeconds: 0,
  monitorCallId: null,
  audioTestCallStatus: '',
}

const state = Vue.observable({
  ...privateStateProps,
  ...publicStateProps,
  ...publicReadOnlyStateProps,
})

/* -------------------------------------------------------------------------- */
/*                            Setup Provider Driver                           */
/* -------------------------------------------------------------------------- */
const providerDriver = () => plivoDriver(state)

/* -------------------------------------------------------------------------- */
/*                 Getters / Mutations / Computed / Actions                   */
/* -------------------------------------------------------------------------- */
const initComputedProperties = () => {
  const props = [
    'callDisposition',
    'callDispositionGroup',
    'currentActionId',
    'currentDialSessionId',
    'currentPhoneRecord',
    'activeCallAnswered',
    'activeCallDurationSecs',
    'secondParticipant',
    'secondParticipantRinging',
    'secondParticipantConnected',
    'hungUpNumberInMpc',
    'redialNumberInMpc',
    'localPresenceOutboundCaller',
    'note',
    'provider',
    'selectedOutboundCaller',
    'subject',
    'transferInProgress',
    'machineDetected',
    'dialerPermissionsBlocked',
    'dialerSearchInput',
    'selectedMessageThread',
    'hasUnreadTextMessage',
    'hasMissedCall',
    'feedbackIssues',
    'feedbackRating',
    'feedbackNote',
    'feedbackReportedSpam',
    'audioTestCallInProgress',
    'audioTestResultsLoading',
    'audioTestResults',
    'isHubspotDialer',
  ]
  const computedProperties = {}
  props.forEach(
    (prop) =>
      (computedProperties[prop] = {
        get: () =>
          dialerServiceGetters[`get${prop[0].toUpperCase()}${prop.slice(1)}`](),
        set: (newVal) =>
          dialerServiceMutations[`set${prop[0].toUpperCase()}${prop.slice(1)}`](
            newVal
          ),
      })
  )
  return computedProperties
}

const initGetterProperties = () => {
  const props = [
    'callBackNumberLocalPresenceStatus',
    'callCompleted',
    'callConnectivityIssues',
    'callDisposition',
    'callDispositionGroup',
    'callId',
    'activeCallAnswered',
    'callRecordingComplianceStatus',
    'currentActionId',
    'currentDialSessionId',
    'deviceLog',
    'forceRecordOption',
    'isDialerOpen',
    'isMuted',
    'isRecording',
    'localPresenceOutboundCaller',
    'onPhone',
    'volume',
    'currentPhoneRecord',
    'secondParticipant',
    'secondParticipantRinging',
    'secondParticipantConnected',
    'hungUpNumberInMpc',
    'redialNumberInMpc',
    'note',
    'provider',
    'selectedOutboundCallerObj',
    'selectedOutboundCaller',
    'subject',
    'machineDetected',
    'dialerPermissionsBlocked',
    'transferredTo',
    'transferInProgress',
    'dialerSearchInput',
    'selectedMessageThread',
    'hasUnreadTextMessage',
    'hasMissedCall',
    'callSeconds',
    'monitorCallId',
    'feedbackIssues',
    'feedbackRating',
    'feedbackNote',
    'feedbackReportedSpam',
    'audioTestCallInProgress',
    'audioTestResultsLoading',
    'audioTestResults',
    'isHubspotDialer',
  ]
  const getterProperties = {}
  props.forEach(
    (prop) =>
      (getterProperties[`get${prop[0].toUpperCase()}${prop.slice(1)}`] = () =>
        state[prop])
  )
  return getterProperties
}

const dialerServiceGetters = {
  ...initGetterProperties(),
  activeCallDuration: () => {
    const duration = state.activeCallDurationSecs
    if (!duration) return '00:00'

    return convertSecondsToHoursMinutesSeconds(duration)
  },
  outboundMessagesEnabled: () =>
    !!FeatureFlags.outboundTextMessagingEnabled() &&
    !!selectedOutboundSMSPhone(),
  autoImportPeopleByDialedNumber: () =>
    getSettingByKey(UserSettings.AUTO_IMPORT_PEOPLE_BY_DIALED_NUMBER),
  autoRecordCalls: () =>
    getSettingByKey(UserSettings.AUTO_RECORD_CALLS) ? true : false,
  deviceConnectedToACall: () => !!state.device?.getCallUUID?.() || false,
  callIsInbound: () => providerDriver().currentCallIsInbound() || false,
  callIsTransfer: () =>
    providerDriver().currentIncomingCallParams()?.isTransfer || false,
  currentAccountId: () => state.currentPhoneRecord?.account?.id,
  currentExternalId: () => state.currentPhoneRecord?.externalId,
  currentExternalObjectType: () => state.currentPhoneRecord?.externalObjectType,
  secondParticipantNumber: () => state.secondParticipant?.number,
  secondParticipantNumberId: () => state.secondParticipant?.id,
  mergeCallLoader: () => state.mergeCallLoader,
  currentNumber: () => state.currentPhoneRecord?.number,
  currentNumberId: () => state.currentPhoneRecord?.id,
  currentPhoneRecordAccount: () => state.currentPhoneRecord?.account,
  currentPhoneRecordEmail: () => state.currentPhoneRecord?.workEmail,
  currentProspectId: () => state.currentPhoneRecord?.prospect?.id,
  currentProspectName: () => state.currentPhoneRecord?.fullName,
  currentProspectTags: () => state.currentPhoneRecord?.tags,
  getCallOutcomes: () =>
    _sortBy(
      getSettingByKey(UserSettings.CALL_DISPOSITIONS)?.filter(
        (d) => !d.disabled
      ) || [],
      'order'
    ),
  allCallOutcomes: () => getSettingByKey(UserSettings.CALL_DISPOSITIONS) || [],
  hasCallRecording: () =>
    getSettingByKey(UserSettings.HAS_CALL_AUDIO_STORAGE) ? true : false,
  hasLocalPresence: () =>
    getSettingByKey(UserSettings.HAS_LOCAL_PRESENCE) ? true : false,
  noiseSuppressionEnabled: () =>
    getSettingByKey(UserSettings.NOISE_SUPPRESSION_ENABLED),
  oneClickDialEnabled: () =>
    getSettingByKey(UserSettings.ONE_CLICK_DIAL_ENABLED),
  incomingCall: () => state.incomingCalls.length,
  incomingCallParams: () => providerDriver().currentIncomingCallParams(),
  needsSetupState: () =>
    !phonesLoading() && (!state.device || !outboundActivatedPhones().length),
  noOutboundCallers: () => !outboundActivatedPhones().length,
  onThreeWayCall: () =>
    state.onPhone &&
    !!state.secondParticipant &&
    !state.secondParticipantRinging &&
    !state.secondParticipantConnected,
  onCallState: () => state.onPhone && !state.callCompleted,
  postCallState: () => !state.onPhone && state.callCompleted,
  preCallState: () => !state.onPhone && !state.callCompleted,
  monitoringCallState: () => !!state.monitorCallId,
  useGroupedCallOutcomes: () =>
    getSettingByKey(UserSettings.USE_GROUPED_CALL_OUTCOMES),
  useLocalPresence: () => getSettingByKey(UserSettings.USE_LOCAL_PRESENCE),
  availableMicrophones: () =>
    state.availableDevices?.filter((d) => d.kind === 'audioinput'),
  availableSpeakers: () =>
    state.availableDevices?.filter((d) => d.kind === 'audiooutput'),
  selectedMicrophone: () => state.selectedMicrophone,
  selectedSpeaker: () => state.selectedSpeaker,
  audioTestCallRinging: () => state.audioTestCallStatus === 'Ringing',
  audioTestCallConnected: () => state.audioTestCallStatus === 'Connected',
  isBluetoothMicSelected: () => {
    const selectedMicLabel =
      (dialerServiceGetters.availableMicrophones() || [])
        .find(
          (item) => item.deviceId === dialerServiceGetters.selectedMicrophone()
        )
        ?.label?.toLowerCase?.() || ''
    return (
      selectedMicLabel.includes('airpods') ||
      selectedMicLabel.includes('bluetooth')
    )
  },
  hasAnyFeedback: () =>
    state.feedbackIssues?.length ||
    !!state.feedbackRating ||
    !!state.feedbackNote ||
    !!state.feedbackReportedSpam,

  allowUserToToggleCallRecording: () => {
    const orgSetting = getSettingByKey(
      UserSettings.ALLOW_USERS_TO_TOGGLE_CALL_RECORDING
    )
    const userOverrides =
      getSettingByKey(UserSettings.CALL_RECORDING_OVERRIDES) || []

    const overrideForCurrentUser = userOverrides.find(
      (i) => i.user_id === user()?.id
    )?.value

    if (overrideForCurrentUser != null) return overrideForCurrentUser

    return orgSetting
  },

  shouldDisableRecordingBtn: () => {
    return (
      !isOrgAdmin() &&
      dialerServiceGetters.allowUserToToggleCallRecording() !== true
    ) // Disabled if not allowed by org setting or if calling into a DNR region
  },

  isSalesforceDialerWidget: () => {
    return !!router.currentRoute.query.sfdcIframeOrigin
  },
}

const dialerServiceMutations = {
  setCall: (newVal) => (state.call = newVal),
  setActiveCallAnswered: (newVal) => (state.activeCallAnswered = newVal),
  setCallBackNumberLocalPresenceStatus: (newVal) =>
    (state.callBackNumberLocalPresenceStatus = newVal),
  setCallCompleted: (newVal) => (state.callCompleted = newVal),
  setCallConnectivityIssues: (newVal) =>
    (state.callConnectivityIssues = _cloneDeep(newVal)),
  setCallDisposition: (newVal) =>
    (state.callDisposition = newVal ? _cloneDeep(newVal) : null),
  setCallDispositionGroup: (newVal) =>
    (state.callDispositionGroup = newVal ? _cloneDeep(newVal) : null),
  setCallId: (newVal) => (state.callId = newVal),
  setCallRecordingComplianceStatus: (newVal) =>
    (state.callRecordingComplianceStatus = newVal),
  setCurrentActionId: (newVal) => (state.currentActionId = newVal),
  setCurrentDialSessionId: (newVal) => (state.currentDialSessionId = newVal),
  setCurrentNumber: (newVal) => {
    state.currentPhoneRecord = {
      ..._cloneDeep(state.currentPhoneRecord),
      number: newVal,
    }
  },
  setCurrentFullName: (newVal) => {
    state.currentPhoneRecord = {
      ..._cloneDeep(state.currentPhoneRecord),
      fullName: newVal,
    }
  },
  setCurrentPhoneRecord: (record) => {
    state.currentPhoneRecord = _cloneDeep(record)
  },
  setSecondParticipant: (record) =>
    (state.secondParticipant = _cloneDeep(record)),
  resetSecondParticipant: () => {
    state.secondParticipant = null
    state.secondParticipantRinging = false
    state.secondParticipantConnected = false
  },
  resetHungupAndRedial: () => {
    state.hungUpNumberInMpc = null
    state.redialNumberInMpc = null
  },
  setSecondParticipantRinging: (val) => (state.secondParticipantRinging = val),
  setSecondParticipantConnected: (val) =>
    (state.secondParticipantConnected = val),
  setHungUpNumberInMpc: (val) => (state.hungUpNumberInMpc = val),
  setRedialNumberInMpc: (val) => (state.redialNumberInMpc = val),
  setMergeCallLoader: (val) => (state.mergeCallLoader = val),
  setCurrentSuggestionId: (newVal) => (state.currentSuggestionId = newVal),
  setDeviceLog: (newVal) => (state.deviceLog = newVal),
  setForceRecordOption: (newVal) => (state.forceRecordOption = newVal),
  setIsDialerOpen: (newVal) => (state.isDialerOpen = newVal),
  setIsMuted: (newVal) => (state.isMuted = newVal),
  setIsRinging: (newVal) => {
    state.isRinging = newVal
    if (newVal) EventBus.$emit('call-incoming')
  },
  setLocalPresenceOutboundCaller: (caller) => {
    if (caller) state.callBackNumberLocalPresenceStatus = 'have_number'
    state.localPresenceOutboundCaller = caller
  },
  setNote: (newVal) => (state.note = newVal),
  setOnPhone: (newVal) => (state.onPhone = newVal),
  setProvider: (newVal) => {
    const oldVal = state.provider
    if (newVal != oldVal && (state.device || state.firstLoad)) {
      state.firstLoad = false
      providerDriver().destroyDevice()
      state.provider = newVal
      switchProviderRegistration()
    }
  },
  setQueuedTokenRefresh: (newVal) => (state.queuedTokenRefresh = newVal),
  setRecording: (newVal) => (state.isRecording = newVal),
  setSelectedOutboundCaller: (newVal) => {
    const selectedCallerInSettings = getSettingByKey(
      UserSettings.OUTBOUND_CALLER_ID
    )
    if (newVal && selectedCallerInSettings !== newVal) {
      const calledIdSetting = {
        settings_key: UserSettings.OUTBOUND_CALLER_ID,
        settings_value: newVal,
      }
      const user = store.getters['user/user']
      const updateSettingPayload = {
        userId: user.id,
        settings: calledIdSetting,
      }
      store.dispatch('user/updateSetting', updateSettingPayload)
    }
    state.selectedOutboundCaller = newVal
    state.selectedOutboundCallerObj = allPhones()?.find(
      (phone) => phone.caller_id === state.selectedOutboundCaller
    )
  },
  setSubject: (newVal) => (state.subject = newVal),
  setVolume: (newVal) => (state.volume = newVal),
  setMachineDetected: (newVal) => (state.machineDetected = newVal),
  setDialerPermissionsBlocked: (newVal) =>
    (state.dialerPermissionsBlocked = newVal),
  setTransferredTo: (newVal) => (state.transferredTo = newVal),
  setTransferInProgress: (newVal) => (state.transferInProgress = newVal),
  setDialerSearchInput: (newVal) => (state.dialerSearchInput = newVal),
  setSelectedMessageThread: (newVal) => (state.selectedMessageThread = newVal),
  setHasUnreadTextMessage: (newVal) => (state.hasUnreadTextMessage = newVal),
  setHasMissedCall: (newVal) => (state.hasMissedCall = newVal),
  setMonitorCallId: (newVal) => (state.monitorCallId = newVal),
  setFeedbackIssues: (newVal) => (state.feedbackIssues = newVal),
  setFeedbackRating: (newVal) => (state.feedbackRating = newVal),
  setFeedbackNote: (newVal) => (state.feedbackNote = newVal),
  setFeedbackReportedSpam: (newVal) => (state.feedbackReportedSpam = newVal),
  setAudioTestCallInProgress: (newVal) =>
    (state.audioTestCallInProgress = newVal),
  setAudioTestCallStatus: (newVal) => (state.audioTestCallStatus = newVal),
  setAudioTestResultsLoading: (newVal) =>
    (state.audioTestResultsLoading = newVal),
  setAudioTestResults: (newVal) => (state.audioTestResults = newVal),
  setIsHubspotDialer: (newVal) => (state.isHubspotDialer = newVal),
}

const dialerServiceComputed = initComputedProperties()

const dialerServiceActions = {
  initDialerService: async () => {
    console.log('📞 Init dialer service...')
    if (state.firstLoad) setDefaultProvider()
    if (dialerServiceGetters.onCallState()) return
    await dialerServiceActions.fetchPhones()

    if (!dialerServiceGetters.useLocalPresence()) {
      setCallerIdIfNotSet()
    } else {
      dialerServiceMutations.setProvider('plivo')
    }
    if (providerDriver().isRegistered()) return
    if (state.device && !providerDriver().isRegistered()) {
      await providerDriver().registerDevice()
      return
    }
    await providerDriver().initDevice()
    setupPlugins()
    if (!state.device) return
    providerDriver().setupDeviceEventListener()
    setupPreventRefreshWhileOnCall()
    keepAlive()
    providerDriver().registerDevice()
    state.firstLoad = false
    dialerServiceActions.fetchAvailableAudioDevices()
  },

  forceDeviceRegister: async () => {
    await providerDriver()?.forceDeviceRegister()
  },

  acceptIncomingCall: async () => {
    if (dialerServiceGetters.getOnPhone()) {
      // End the current call
      await dialerServiceActions.disconnectCall()
    }
    if (state.secondParticipant) {
      const defaultCountryCode =
        getSettingByKey(UserSettings.ISO_COUNTRY_CODE) || 'US'
      dialerServiceActions.removeSecondParticipant(
        isSameNumber(
          state.secondParticipant,
          state.hungUpNumberInMpc,
          defaultCountryCode
        )
      )
    }
    dialerServiceMutations.setIsMuted(false)
    dialerServiceMutations.setOnPhone(true)
    dialerServiceMutations.setIsDialerOpen(true)
    dialerServiceMutations.setDeviceLog('Accept call...')
    dialerServiceMutations.setCallCompleted(false)
    const incomingCallParams = dialerServiceGetters.incomingCallParams()
    console.log('incomingCallParams', incomingCallParams)
    dialerServiceMutations.setCurrentPhoneRecord({
      number: incomingCallParams.from,
      fullName: incomingCallParams.prospectName,
      externalId: incomingCallParams.externalId,
      prospect: incomingCallParams.prospectId
        ? {
            id: incomingCallParams.prospectId,
          }
        : null,
    })
    providerDriver().acceptIncomingCall()
    startCallTimer()
  },

  ignoreIncomingCall: () => providerDriver().ignoreIncomingCall(),

  connectCall: async () => {
    if (dialerServiceGetters.getOnPhone()) return
    dialerServiceMutations.setIsMuted(false)
    dialerServiceMutations.setOnPhone(true)
    dialerServiceMutations.setCallCompleted(false)
    await providerDriver().connectCall({
      number: dialerServiceGetters.currentNumber(),
      phoneNumberId: dialerServiceGetters.currentNumberId(),
      audioTest: dialerServiceGetters.getAudioTestCallInProgress(),
    })
    dialerServiceMutations.setDeviceLog(
      `Calling ${dialerServiceGetters.currentNumber()}...`
    )
    EventBus.$emit('call-started')
    providerDriver().setupCallEventListener()
  },

  startCallDuration: () => {
    state.callDurationInterval = setInterval(() => {
      state.activeCallDurationSecs += 1
    }, 1000)
  },
  stopCallDuration: () => {
    clearInterval(state.callDurationInterval)
    state.callDurationInterval = null
  },
  resetCallDuration: () => {
    dialerServiceActions.stopCallDuration()
    state.activeCallDurationSecs = 0
  },

  startAudioTestCall: async () => {
    dialerServiceMutations.setAudioTestCallInProgress(true)
    dialerServiceMutations.setAudioTestCallStatus('Ringing')
    dialerServiceActions.connectCall()
  },

  showAudioTestResults: async (results) => {
    dialerServiceMutations.setAudioTestResultsLoading(false)
    dialerServiceMutations.setAudioTestResults(results)
  },

  endAudioTestCall: async () => {
    dialerServiceActions.disconnectCall()
    dialerServiceMutations.setAudioTestResultsLoading(true)
    dialerServiceMutations.setAudioTestCallStatus('Ended')

    // Execute fallback logic to get the last test call if socketi event is not received
    // Executed after 30 seconds
    setTimeout(() => {
      if (
        dialerServiceGetters.getAudioTestCallInProgress() &&
        dialerServiceGetters.getAudioTestResultsLoading()
      )
        CallApi.getLastTestCall().then((res) => {
          dialerServiceMutations.setAudioTestResults(res)
          dialerServiceMutations.setAudioTestResultsLoading(false)
        })
    }, 30000)
  },

  exitAudioTestCall: async () => {
    dialerServiceActions.resetAudioTestCall()
    dialerServiceMutations.setAudioTestCallInProgress(false)
  },

  resetAudioTestCall: async () => {
    dialerServiceMutations.setAudioTestResults(null)
    dialerServiceMutations.setAudioTestResultsLoading(false)
    dialerServiceMutations.setAudioTestCallStatus('')
  },

  disconnectCall: async () => {
    await providerDriver().disconnectCall()

    setTimeout(() => localStorage.removeItem('PlivoLogStorage'), 5000) // Waiting for 5 seconds and then clearing the plivo log storage to avoid quota exceed
  },

  toggleCall: async () => {
    dialerServiceMutations.setDialerSearchInput('')
    if (dialerServiceGetters.getOnPhone()) {
      console.log('📞 Toggle hangup call')
      dialerServiceActions.disconnectCall()
    } else {
      console.log('📞 Toggle dial call')
      await dialerServiceActions.connectCall()
    }
  },

  startMonitoringCall: async (callId) => {
    if (dialerServiceGetters.getOnPhone()) return
    console.log('📞 Start monitoring call', callId)
    dialerServiceMutations.setMonitorCallId(callId)
    dialerServiceMutations.setOnPhone(true)
    await providerDriver().connectCall({
      monitorCallId: callId,
    })
    dialerServiceMutations.setDeviceLog(`Coaching call ${callId}...`)
  },

  stopMonitoringCall: async () => {
    if (!dialerServiceGetters.getOnPhone()) return
    await providerDriver().disconnectCall()
    dialerServiceMutations.setDeviceLog(`Stopped coaching call...`)
  },

  redialInMpc: async (record) => {
    dialerServiceMutations.setHungUpNumberInMpc(null)
    dialerServiceMutations.setRedialNumberInMpc(record)
    return CallApi.addToCall({
      callId: dialerServiceGetters.getCallId(),
      to: record.number || record.to,
      auto_merge: true,
    })
  },

  addToCall: async (record) => {
    dialerServiceMutations.resetHungupAndRedial()
    return CallApi.addToCall({
      callId: dialerServiceGetters.getCallId(),
      to: record.number,
    })
  },

  removeSecondParticipant: async (isAlreadyHungup = false) => {
    if (isAlreadyHungup) {
      dialerServiceMutations.resetSecondParticipant()
      dialerServiceMutations.resetHungupAndRedial()
      return
    } else {
      const number =
        state.secondParticipant?.to?.replace('+', '')?.replaceAll(' ', '') ||
        state.secondParticipant?.number?.replace('+', '')?.replaceAll(' ', '')
      return new Promise((resolve, reject) => {
        CallApi.removeFromCall({
          callId: dialerServiceGetters.getCallId(),
          to: number,
        })
          .then(() => resolve())
          .catch((err) => reject(err))
          .finally(() => {
            dialerServiceMutations.resetSecondParticipant()
            dialerServiceMutations.resetHungupAndRedial()
          })
      })
    }
  },

  startCallTransfer: async (group) => {
    dialerServiceMutations.setTransferredTo(group)
    return CallApi.startTransfer({
      callId: dialerServiceGetters.getCallId(),
      groupDetails: { transfer_group_id: group.id, is_user: group.is_user },
    })
  },

  completeCallTransfer: async () => {
    dialerServiceMutations.setTransferredTo(null)
    return CallApi.completeTransfer({
      callId: dialerServiceGetters.getCallId(),
    }).then(() => {
      dialerServiceActions.resetState()
    })
  },

  abortCallTransfer: async () => {
    dialerServiceMutations.setTransferredTo(null)
    return CallApi.abortTransfer({
      callId: dialerServiceGetters.getCallId(),
    })
  },

  abortAddToCall: async (record) => {
    const number =
      record?.to?.replace('+', '')?.replaceAll(' ', '') ||
      record?.number?.replace('+', '')?.replaceAll(' ', '')
    if (!dialerServiceGetters.getRedialNumberInMpc())
      dialerServiceMutations.resetSecondParticipant()
    return CallApi.abortAddToCall({
      callId: dialerServiceGetters.getCallId(),
      abort_to: number,
    }).then(() => {
      // If aborting redial
      if (dialerServiceGetters.getRedialNumberInMpc()) {
        // Move redial number back to hung up number
        dialerServiceMutations.setHungUpNumberInMpc(
          _cloneDeep(dialerServiceGetters.getRedialNumberInMpc())
        )
        // Reset redial number
        dialerServiceMutations.setRedialNumberInMpc(null)
      }
    })
  },

  mergeAddToCall: async () => {
    dialerServiceMutations.resetHungupAndRedial()
    dialerServiceMutations.setMergeCallLoader(true)
    return CallApi.mergeAddToCall({
      callId: dialerServiceGetters.getCallId(),
    })
      .then(() => {
        dialerServiceMutations.setSecondParticipantRinging(false)
        dialerServiceMutations.setSecondParticipantConnected(false)
      })
      .finally(() => dialerServiceMutations.setMergeCallLoader(false))
  },

  dropVoicemail: (id) => {
    CallApi.dropVoicemail(
      dialerServiceGetters.getCallId(),
      id ? { voicemail_id: id } : null
    )
  },

  doubleDial: () => {
    CallApi.doubleDial(dialerServiceGetters.getCallId()).then(() =>
      dialerServiceMutations.setMachineDetected(false)
    )
  },

  sendDigit: (digit) => providerDriver().sendDigit(digit),
  toggleMute: () => providerDriver().toggleMute(),
  toggleOpen: () => {
    if (isGHLWidget()) window.parent.postMessage('minimize-dialer', '*')
    else
      dialerServiceMutations.setIsDialerOpen(
        !dialerServiceGetters.getIsDialerOpen()
      )
  },

  openDialer: () => dialerServiceMutations.setIsDialerOpen(true),
  closeDialer: () => dialerServiceMutations.setIsDialerOpen(false),
  toggleRecording: (options = {}) => {
    console.log('toggleRecording', options)
    if (options.forceRecordOption !== '')
      dialerServiceMutations.setForceRecordOption(options.forceRecordOption)
    if (!dialerServiceGetters.getCallId()) return
    if (dialerServiceGetters.getIsRecording()) {
      CallApi.pauseRecording(dialerServiceGetters.getCallId())
        .then(() => {
          dialerServiceMutations.setRecording(false)
          sendSnackMessage('Call recording paused', 'success')
        })
        .catch(() => {
          dialerServiceMutations.setRecording(true)
          sendSnackMessage(
            'There was an error pausing the recording, please try again.',
            'error'
          )
        })
    } else {
      CallApi.startRecording(dialerServiceGetters.getCallId())
        .then(() => {
          dialerServiceMutations.setRecording(true)
          sendSnackMessage('Call recording started', 'success')
        })
        .catch(() => {
          dialerServiceMutations.setRecording(false)
          sendSnackMessage(
            'There was an error starting the recording, please try again.',
            'error'
          )
        })
    }
  },

  fetchCallRecordingComplianceStatus: () => {
    if (
      !dialerServiceGetters.currentNumber() ||
      !dialerServiceGetters.hasCallRecording()
    )
      return

    UtilsAPi.getCallRecordingCompliance({
      to: dialerServiceGetters.currentNumber(),
    })
      .then((complianceCode) => {
        if (complianceCode !== 'record') {
          dialerServiceActions.toggleRecording({
            forceRecordOption: 'do_not_record',
          })
        }
        dialerServiceMutations.setCallRecordingComplianceStatus(complianceCode)
      })
      .catch(() => {
        dialerServiceMutations.setCallRecordingComplianceStatus('record')
      })
  },

  toggleLocalPresence: (newVal = null) => {
    return new Promise((resolve, reject) => {
      const settingsPayload = {
        userId: user().id,
        settings: {
          settings: [
            {
              key: 'useLocalPresence',
              value:
                typeof newVal === 'boolean'
                  ? newVal
                  : !dialerServiceGetters.useLocalPresence(),
            },
          ],
        },
      }
      store
        .dispatch('user/updateSetting', settingsPayload)
        .then(() => {
          sendSnackMessage(
            dialerServiceGetters.useLocalPresence()
              ? 'Local presence dialing enabled'
              : 'Local presence dialing disabled',
            'success'
          )
          if (dialerServiceGetters.useLocalPresence()) {
            dialerServiceMutations.setProvider('plivo')
            dialerServiceMutations.setSelectedOutboundCaller(null)
            dialerServiceActions.fetchLocalPresenceNumber()
          } else {
            dialerServiceMutations.setLocalPresenceOutboundCaller(null)
            setCallerIdIfNotSet()
          }
          resolve()
        })
        .catch((err) => {
          sendSnackMessage(
            'There was an error saving the local presence settings, try again',
            'error'
          )
          reject(err)
        })
        .finally(() => {
          resolve()
        })
    })
  },

  fetchLocalPresenceNumber: () => {
    if (!dialerServiceGetters.currentNumber()) return
    return UtilsAPi.getLocalPresenceNumber({
      to: dialerServiceGetters.currentNumber(),
      provider: dialerServiceGetters.getProvider(),
    })
      .then((number) => {
        if (!number)
          dialerServiceMutations.setCallBackNumberLocalPresenceStatus(
            'will_buy_number'
          )
        else dialerServiceMutations.setLocalPresenceOutboundCaller(number)
      })
      .catch(() => {
        dialerServiceMutations.setCallBackNumberLocalPresenceStatus(
          'unavailable'
        )
        dialerServiceMutations.setLocalPresenceOutboundCaller(null)
      })
  },

  postCallFeedback: () => {
    return new Promise((resolve, reject) => {
      CallApi.sendCallFeedback(dialerServiceGetters.getCallId(), {
        feedback_issues: dialerServiceGetters.getFeedbackIssues(),
        feedback_rating: dialerServiceGetters.getFeedbackRating(), //1 to 5
        feedback_note: dialerServiceGetters.getFeedbackNote(),
        callee_indicated_number_spam:
          dialerServiceGetters.getFeedbackReportedSpam(),
      })
        .then(() => {
          sendSnackMessage('Feedback posted', 'success')
          resolve()
        })
        .catch(() => {
          sendSnackMessage(
            'There was an error sending the call feedback, please try again.',
            'error'
          )
          reject()
        })
    })
  },
  updateCall: (saveNoteOnly = false) => {
    let call = {
      subject: dialerServiceGetters.getSubject(),
      note: dialerServiceGetters.getNote(),
    }
    if (!saveNoteOnly) {
      call = {
        ...call,
        call_disposition: dialerServiceGetters.getCallDisposition().value,
        call_disposition_group:
          dialerServiceGetters.getCallDispositionGroup()?.value === 'All'
            ? dialerServiceGetters.getCallDisposition()?.group?.name
            : dialerServiceGetters.getCallDispositionGroup()?.value,
      }
    }
    return new Promise((resolve, reject) => {
      CallApi.updateCall({
        callId: dialerServiceGetters.getCallId(),
        call,
      })
        .then(() => {
          if (saveNoteOnly) {
            resolve()
            return
          }

          sendSnackMessage('Call updated', 'success')
          if (dialerServiceGetters.getCurrentActionId())
            markActionComplete(dialerServiceGetters.getCurrentActionId())
          resolve()
        })
        .catch(() => {
          sendSnackMessage(
            'There was an error updating the call, please try again.',
            'error'
          )
          reject()
        })
        .finally(() => {
          if (!saveNoteOnly) {
            callComplete()
            // closeCrmDialer()
            dialerServiceActions.resetState()
          }
        })
    })
  },

  getCallOutcomeFriendlyName: (slug) => {
    if (!slug) return
    const callOutcomes = dialerServiceGetters.allCallOutcomes()
    const callOutcome = callOutcomes.find((o) => o.value == slug)
    return callOutcome ? callOutcome.name : slug
  },

  forceTokenRefresh: () => {
    providerDriver().updatePhoneToken()
  },

  fetchCallByExternalId: (externalId = null) => {
    if (
      !state.call ||
      !providerDriver().currentCallProviderId() ||
      dialerServiceGetters.monitoringCallState()
    ) {
      return
    }

    let id =
      externalId ||
      state.call?.['X-Ph-Incoming-Call-Id'] ||
      providerDriver().currentCallProviderId()

    let getCallFunc = CallApi.getCallByExternalId
    const callIsInbound = dialerServiceGetters.callIsInbound()

    if (callIsInbound && state.call?.['X-Ph-Symbo-Call-Id']) {
      id = state.call?.['X-Ph-Symbo-Call-Id']
      getCallFunc = CallApi.getCallById
    }

    getCallFunc(id)
      .then((resp) => {
        if (Array.isArray(resp.data) && !resp.data?.length) return

        const call =
          Array.isArray(resp.data) && resp.data?.length ? resp.data[0] : resp

        const outboundCallerId = !callIsInbound
          ? call.from_formatted
          : call.to_formatted
        if (outboundCallerId && !dialerServiceGetters.useLocalPresence())
          dialerServiceMutations.setSelectedOutboundCaller(
            outboundCallerId?.replaceAll(' ', '')
          )
        dialerServiceMutations.setCallId(call.id)
        dialerServiceMutations.setRecording(
          call.should_record_call === 'record' ||
            call.should_record_call === 'force_record'
        )
        dialerServiceMutations.setCurrentNumber(
          !callIsInbound ? call.to_formatted : call.from_formatted
        )
      })
      .catch((error) => {
        console.log('Call fetch error', error)
        sendSnackMessage('Error retrieving the call data.', 'error')
      })
  },

  markCallAnswered: () => {
    dialerServiceMutations.setDeviceLog('Call answered...')
    EventBus.$emit('call-answered')
    startCallTimer()
  },

  markCallDisconnected: () => {
    if (dialerServiceGetters.getAudioTestCallInProgress()) {
      dialerServiceMutations.setOnPhone(false)
      dialerServiceActions.resetState()
      return
    }

    dialerServiceMutations.setDeviceLog('Disconnected...')
    dialerServiceMutations.setOnPhone(false)
    if (dialerServiceGetters.monitoringCallState()) {
      dialerServiceMutations.setMonitorCallId(null)
      dialerServiceActions.resetState()
      return
    }
    if (dialerServiceGetters.getTransferInProgress()) {
      dialerServiceMutations.setTransferInProgress(false)
      dialerServiceActions.resetState()
    }
    if (state.call) dialerServiceMutations.setCallCompleted(true)
    EventBus.$emit('call-ended')
    sendSnackMessage('Call complete', 'success')
    stopCallTimer()
  },

  resetState: () => {
    console.log('📞 Resetting dialer state...')
    resetCurrentContact()
    dialerServiceMutations.setCurrentActionId('')
    dialerServiceMutations.setCurrentSuggestionId('')
    dialerServiceMutations.setCallId('')
    dialerServiceMutations.setCallDisposition(null)
    dialerServiceMutations.setCallDispositionGroup(null)
    dialerServiceMutations.setNote('')
    dialerServiceMutations.setSubject('')
    dialerServiceMutations.setDialerSearchInput('')
    dialerServiceMutations.setCallCompleted(false)
    dialerServiceMutations.setVolume(0)
    dialerServiceMutations.setFeedbackRating(0)
    dialerServiceMutations.setFeedbackIssues([])
    dialerServiceMutations.setFeedbackNote('')
    dialerServiceMutations.setFeedbackReportedSpam(false)
    dialerServiceMutations.setIsMuted(false)
    dialerServiceMutations.setTransferInProgress(false)
    dialerServiceMutations.setMachineDetected(false)
    dialerServiceMutations.setTransferredTo(null)
    dialerServiceMutations.setCallConnectivityIssues([])
    dialerServiceMutations.setMergeCallLoader(false)
    dialerServiceMutations.resetSecondParticipant()
    dialerServiceMutations.resetHungupAndRedial()
    dialerServiceMutations.setActiveCallAnswered(false)
    dialerServiceActions.resetCallDuration()
    dialerServiceActions.resetLocalPresence()
    dialerServiceActions.resetCallRecording()
    dialerServiceMutations.setMonitorCallId(null)
    state.call = null
  },

  fetchPhones: async () => {
    return new Promise((resolve, reject) => {
      store
        .dispatch('phone/fetchPhones')
        .catch((err) => {
          reject(err)
        })
        .finally(() => {
          resolve()
        })
    })
  },

  findCrmRecordByExternalIdOrPhoneNumber: async () => {
    if (
      !dialerServiceGetters.currentNumber() &&
      !dialerServiceGetters.currentExternalId()
    )
      return

    if (dialerServiceGetters.currentExternalId()) {
      CrmApi.findCrmProspectByExternalId({
        externalId: dialerServiceGetters.currentExternalId(),
      })
        .then((response) => {
          const prospectFullName = getFullName(
            response?.prospect?.first_name,
            response?.prospect?.last_name
          )
          if (prospectFullName) {
            dialerServiceMutations.setCurrentFullName(prospectFullName)
          }
        })
        .catch((error) => {
          console.log('Issue finding related CRM record by external id', error)
        })
    } else if (dialerServiceGetters.currentNumber()) {
      CrmApi.findCrmProspectByPhoneNumber({
        phone: dialerServiceGetters.currentNumber(),
      })
        .then((response) => {
          const prospectFullName = getFullName(
            response?.prospect?.first_name,
            response?.prospect?.last_name
          )
          if (prospectFullName) {
            dialerServiceMutations.setCurrentFullName(prospectFullName)
          }
        })
        .catch(() => {
          console.log('Issue finding related CRM record by phone number')
        })
    }
  },

  fetchTransferGroups: async () => {
    return new Promise((resolve, reject) => {
      store
        .dispatch('transferGroups/fetchTransferGroups')
        .catch((err) => {
          reject(err)
        })
        .finally(() => {
          resolve()
        })
    })
  },

  resetCallRecording: () => {
    EventBus.$emit('reset-call-recording')
    dialerServiceMutations.setRecording(false)
  },

  resetLocalPresence: () => {
    state.callBackNumberLocalPresenceStatus = null
    let callerIndex = allPhones().findIndex(
      (caller) =>
        caller.caller_id === dialerServiceGetters.getSelectedOutboundCaller()
    )
    if (!dialerServiceGetters.getSelectedOutboundCaller() || callerIndex === -1)
      callerIndex = 0
    dialerServiceMutations.setSelectedOutboundCaller(
      allPhones()[callerIndex]?.caller_id
    )
  },

  fetchAvailableAudioDevices: async () => {
    await providerDriver().getAvailableDevices()
    setAudioDevicesFromLocalStorage()
  },

  setMicrophoneDevice: (deviceId) => {
    providerDriver().setMicrophoneDevice(deviceId)
    updateAudioDeviceLocalStorage({ microphone: state.selectedMicrophone })
  },

  setSpeakerDevice: (deviceId) => {
    providerDriver().setSpeakerDevice(deviceId)
    updateAudioDeviceLocalStorage({ speaker: state.selectedSpeaker })
  },

  startNoiseSuppression: () => providerDriver().startNoiseSuppression(),

  stopNoiseSuppression: () => providerDriver().stopNoiseSuppression(),
}

/* -------------------------------------------------------------------------- */
/*                              Private Functions                             */
/* -------------------------------------------------------------------------- */

const updateAudioDeviceLocalStorage = ({ microphone, speaker }) => {
  const devices = {
    ...(getLSItem('audioDevices') || {}),
    ...(microphone
      ? {
          microphone: dialerServiceGetters.selectedMicrophone(),
        }
      : {}),
    ...(speaker ? { speaker: dialerServiceGetters.selectedSpeaker() } : {}),
  }
  setLSItem('audioDevices', JSON.stringify(devices))
}

const setAudioDevicesFromLocalStorage = () => {
  // If the selected device is not set, try to set it from the cache
  const cachedDevices = getLSItem('audioDevices') || {}
  if (
    !dialerServiceGetters.selectedMicrophone() &&
    cachedDevices.microphone &&
    cachedDevices.microphone !== ''
  )
    dialerServiceActions.setMicrophoneDevice(cachedDevices?.microphone)
  if (
    !dialerServiceGetters.selectedSpeaker() &&
    cachedDevices.speaker &&
    cachedDevices.speaker !== ''
  )
    dialerServiceActions.setSpeakerDevice(cachedDevices?.speaker)
}

const callComplete = () => {
  if (!state.currentActionId) return
  EventBus.$emit('call-action-completed')
  dialerServiceMutations.setIsDialerOpen(false)
}

// const closeCrmDialer = () => {
//   EventBus.$emit('close-crm-dialer')
// }

const keepAlive = () => {
  const ttl = 600000 // 10 minutes
  const refreshBuffer = 30000 // 30 seconds
  setInterval(async () => {
    providerDriver().updatePhoneToken()
  }, ttl - refreshBuffer)
}

const setupPreventRefreshWhileOnCall = () => {
  window.onbeforeunload = function () {
    return dialerServiceGetters.getOnPhone() ? true : null
  }
}

const setCallerIdIfNotSet = () => {
  if (dialerServiceGetters.getSelectedOutboundCaller() && !state.firstLoad)
    return
  const lastCallerId = getSettingByKey(UserSettings.OUTBOUND_CALLER_ID)
  const isLastCallerIdActivated = outboundActivatedPhones().find(
    (phone) => phone.caller_id === lastCallerId
  )
  if (lastCallerId && isLastCallerIdActivated) {
    const phone = allPhones().find((phone) => phone.caller_id === lastCallerId)
    if (phone) {
      dialerServiceMutations.setProvider(phone.provider)
      dialerServiceMutations.setSelectedOutboundCaller(lastCallerId)
    } else {
      dialerServiceMutations.setProvider(outboundActivatedPhones()[0]?.provider)
      dialerServiceMutations.setSelectedOutboundCaller(
        outboundActivatedPhones()[0]?.caller_id
      )
    }
  } else if (!dialerServiceGetters.getSelectedOutboundCaller()) {
    dialerServiceMutations.setProvider(outboundActivatedPhones()[0]?.provider)
    dialerServiceMutations.setSelectedOutboundCaller(
      outboundActivatedPhones()[0]?.caller_id
    )
  }
}

const resetCurrentContact = () => {
  dialerServiceMutations.setCurrentPhoneRecord({
    id: null,
    fullName: '',
    number: null,
    workEmail: '',
    account: null,
    prospect: null,
    externalId: null,
    externalObjectType: null,
    tags: [],
  })
}

const switchProviderRegistration = () => {
  state.callConnectivityIssues = []
  dialerServiceActions.initDialerService()
}

const setDefaultProvider = () => {
  if (!state.provider) return
  state.provider = 'plivo'
}

const setupPlugins = () => {
  console.log('📞 Setting up plugins...')

  if (
    user()?.crm?.crm_provider === 'hubspot' &&
    FeatureFlags.isHubspotDialerPluginEnabled()
  ) {
    hubspotPlugin.init(state)
  }

  if (
    dialerServiceGetters.isSalesforceDialerWidget() ||
    (user()?.crm?.crm_provider === 'salesforce' &&
      FeatureFlags.isSalesforceDialerPluginEnabled())
  ) {
    salesforcePlugin.init(state)
  }

  if (user()?.crm?.crm_provider === 'pipedrive') {
    pipedrivePlugin.init(state, user())
  }
}

const startCallTimer = () => {
  if (state.callTimer) return
  state.callSeconds = 0
  state.callTimer = setInterval(() => {
    state.callSeconds++
  }, 1000)
}

const stopCallTimer = () => {
  clearInterval(state.callTimer)
  state.callSeconds = 0
  state.callTimer = null
}

/* -------------------------------------------------------------------------- */
/*                             Event Registrations                            */
/* -------------------------------------------------------------------------- */

EventBus.$on('user-logout', () => {
  providerDriver().destroyDevice()
  state.device = null
  state.provider = null
  state.firstLoad = true
  state.selectedOutboundCaller = null
  dialerServiceActions.resetState()
})

EventBus.$on('start-monitoring-call', (callId) => {
  dialerServiceActions.startMonitoringCall(callId)
})

EventBus.$on('disconnect-call', () => dialerServiceActions.disconnectCall())

EventBus.$on('stop-monitoring-call', () => {
  dialerServiceActions.stopMonitoringCall()
})

EventBus.$on('noise-suppression-updated', () => {
  console.log('📞 Noise suppression updated listener...')
  if (dialerServiceGetters.noiseSuppressionEnabled()) {
    dialerServiceActions.startNoiseSuppression()
  } else {
    dialerServiceActions.stopNoiseSuppression()
  }
})

/* -------------------------------------------------------------------------- */
/*                                   Export                                   */
/* -------------------------------------------------------------------------- */

export {
  dialerServiceActions,
  dialerServiceComputed,
  dialerServiceGetters,
  dialerServiceMutations,
}
