import api from './Api'
import store from '@/store'
import { EventBus } from '@/services/event-bus'
import { getTablePaginationParams, withAsync } from './helpers'

const ENDPOINTS = {
  accounts: (id = '') => (id ? `/accounts/${id}` : `/accounts`),
  bulkAccounts: '/bulk/accounts',
  undeleteAccount: (id) => `${ENDPOINTS.accounts(id)}/actions/undelete`,
  opts: {
    forceDeleteProspects: 'force_delete_prospects=true',
    showDeleted: 'deleted_at=true',
    search: (text) => `name=${text}`,
    owner: (ids) => `owner=${ids.join(',')}`,
  },
  allOrgAccounts: `/allOrgAccounts`,
}

export default {
  getAccountById(id) {
    return api.get(ENDPOINTS.accounts(id), { abortKey: 'GET_ACCOUNT_BY_ID' })
  },

  getAccounts(options = {}) {
    let path = ENDPOINTS.accounts()
    let pathParams = []

    const { searchByName, showDeleted, filtersQuery } = options

    if (showDeleted) pathParams.push(ENDPOINTS.opts.showDeleted)

    if (filtersQuery) pathParams.push(filtersQuery)

    // Table search input
    if (searchByName) pathParams.push(ENDPOINTS.opts.search(searchByName))

    // Pagination
    const pagParams = getTablePaginationParams(options)
    if (pagParams) pathParams.push(pagParams)

    if (pathParams.length) path += '?' + pathParams.join('&')

    return api.get(path, { abortKey: 'GET_ACCOUNTS' })
  },

  getAllOrgAccounts(options = {}) {
    let path = ENDPOINTS.allOrgAccounts
    let pathParams = []

    const { searchByName } = options

    // Table search input
    if (searchByName) pathParams.push(ENDPOINTS.opts.search(searchByName))

    // Pagination
    const pagParams = getTablePaginationParams(options)
    if (pagParams) pathParams.push(pagParams)

    if (pathParams.length) path += '?' + pathParams.join('&')

    return api.get(path)
  },

  async createAccount(account) {
    const path = ENDPOINTS.accounts()
    return api.post(path, account)
  },

  async editAccount(account) {
    const path = ENDPOINTS.accounts(account?.id)
    return api.patch(path, account)
  },

  async deleteAccount(accountId, forceDeleteProspects = false) {
    let path = ENDPOINTS.accounts(accountId)
    if (forceDeleteProspects) path += `?${ENDPOINTS.opts.forceDeleteProspects}`

    const { error } = await withAsync(api.delete, path)

    if (error && error.response?.status === 405) {
      // delete failed due to linked prospects
      store.commit('appModal/showModal', {
        title: 'Heads Up',
        text: 'This account has people linked to it, they will be moved to the trash as well. Proceed?',
        doIfConfirmed: () => {
          return this.deleteAccount(accountId, true)
        },
      })
    } else if (error) {
      store.commit('snackbar/setSnack', {
        snack: 'There was an issue while deleting.',
        snackType: 'error',
      })
    } else {
      EventBus.$emit('people-fetch-accounts')
      store.commit('snackbar/setSnack', {
        snack: 'Successfully deleted',
        snackType: 'success',
      })
    }
  },

  bulkDeleteAccounts(payload) {
    return api.delete(ENDPOINTS.bulkAccounts, {
      data: {
        accounts: payload,
      },
    })
  },

  undoDeleteAccount(id) {
    let path = ENDPOINTS.undeleteAccount(id)
    return api.post(path)
  },

  addAccountByExternalId({ externalId }) {
    return api.post(ENDPOINTS.accounts(), {
      external_id: externalId,
    })
  },

  addBulkAccountsByExternalId(externalIds) {
    return api.post(ENDPOINTS.bulkAccounts, {
      external_ids: externalIds,
    })
  },

  importCrmContacts({ id }) {
    return api.post(`${ENDPOINTS.accounts(id)}/importCrmContacts`)
  },
}
