import Plivo from 'plivo-browser-sdk'
import store from '@/store/index'
import {
  dialerServiceMutations,
  dialerServiceGetters,
  dialerServiceActions,
} from '../DialerService'
import { powerDialGetters } from '@/services/stateful/dialer/PowerDialService'
import { getCallQualityMessage } from '@/constants'
import {
  sendSnackMessage,
  sanitizePhoneNumber,
  formattedInternationalPhoneNumber,
} from '@/utils/helpers'
import { EventBus } from '@/services/event-bus'
import FeatureFlags from '@/services/feature-flags/FeatureFlags'
import { unionBy } from 'lodash'

const user = () => store.getters['user/user']

export const plivoDriver = (state) => {
  /* --------------------------------- Helpers -------------------------------- */
  const setInboundRingtone = () => {
    state.device.setRingTone('https://bigsoundbank.com/UPLOAD/mp3/0253.mp3')
    if (FeatureFlags.friendlyOutboundConnectTone())
      state.device.setConnectTone(false)
    // get the audio element with id plivo_ringtone and set data-ringtone attribute
    window.document
      .getElementById('plivo_ringtone')
      .setAttribute('data-devicetype', 'speakerDevice')
  }

  const getCurrentIncomingCallParams = (externalId) => {
    const incomingCall = getIncomingCall(externalId)
    console.log('getCurrentIncomingCallParams', incomingCall)
    return {
      isTransfer: incomingCall?.['X-Ph-Is-Transfer'] === 'true',
      from: incomingCall?.from,
      incomingCallId: incomingCall?.['X-Ph-Incoming-Call-Id'],
      symboCallId: incomingCall?.['X-Ph-Symbo-Call-Id'],
      externalId: incomingCall?.['X-Ph-External-Id'],
      prospectId: incomingCall?.['X-Ph-Prospect-Id'],
      prospectName: incomingCall?.['X-Ph-Prospect-Name']?.replaceAll('-', ' '),
      prospectPhone: formattedInternationalPhoneNumber(
        incomingCall?.['X-Ph-Prospect-Phone']
      ),
      prospectLocation: incomingCall?.['X-Ph-Prospect-Location']?.replaceAll(
        '-',
        ', '
      ),
      accountName: incomingCall?.['X-Ph-Account-Name']?.replaceAll('-', ' '),
      originalUserName: incomingCall?.['X-Ph-Original-User-Name']?.replaceAll(
        '-',
        ' '
      ),
      accountWebsite: incomingCall?.['X-Ph-Account-Website'],
    }
  }

  const loginDeviceIfNotLoggedIn = () => {
    if (!state.device) return

    if (!state.device?.isLoggedIn) {
      state.device.login(user()?.plivo_endpoint_user, user()?.plivo_endpoint_pw)
      console.log('📞 New device initialized...')
    }
  }

  const forceDeviceRegister = async () => {
    await state.device?.logout()
    await state.device?.login(
      user()?.plivo_endpoint_user,
      user()?.plivo_endpoint_pw
    )
    console.log('📞 Device registration forced...')
  }

  const getIncomingCall = (externalCallId) => {
    if (!state.incomingCalls.length) return null
    if (!externalCallId) return state.incomingCalls[0]
    return state.incomingCalls.find((call) => call.callUUID === externalCallId)
  }

  const removeIncomingCall = (externalCallId) => {
    if (!state.incomingCalls.length) return

    const index = state.incomingCalls.findIndex(
      (call) => call.callUUID === externalCallId
    )
    if (index > -1) state.incomingCalls.splice(index, 1)
  }

  const ignoreIncomingCall = (externalCallId = null) => {
    if (!externalCallId) {
      state.incomingCalls.forEach((call) => {
        state.device.ignore(call.callUUID)
        removeIncomingCall(call.callUUID)
      })
      return
    }

    // Ignore the call
    const ignored = state.device.ignore(externalCallId)
    console.log('📞 Ignored call...', ignored)
    // Remove the incoming call from the incomingCalls array
    removeIncomingCall(externalCallId)
    dialerServiceMutations.setDeviceLog('Ignored call...')
  }

  const setMicMute = (shouldMute = true) => {
    dialerServiceMutations.setIsMuted(shouldMute)
    shouldMute ? state.device.mute() : state.device.unmute()
  }

  return {
    initDevice: async () => {
      console.log(
        `Stun server enabled: ${FeatureFlags.plivoStunServersEnabled()}`
      )
      const options = {
        debug: 'WARN',
        permOnClick: true,
        enableTracking: true,
        closeProtection: true,
        maxAverageBitrate: 48000,
        useDefaultAudioDevice: false,
        allowMultipleIncomingCalls: true,
        enableNoiseReduction: true, // This is always enabled, but started and stopped based on user settings
        usePlivoStunServers: !!FeatureFlags.plivoStunServersEnabled(),
      }
      const plivoBrowserSdk = new Plivo(options)
      state.device = plivoBrowserSdk.client
      setInboundRingtone()
    },
    registerDevice: () => loginDeviceIfNotLoggedIn(),
    isRegistered: () => state.device?.isLoggedIn,
    currentCallProviderId: () => state.call?.callUUID,
    currentCallIsInbound: () =>
      state.call && state.call?.direction == 'incoming',
    currentIncomingCallParams: () => getCurrentIncomingCallParams(),
    setupDeviceEventListener: () => {
      state.device.on('onWebrtcNotSupported', () => {
        console.log('📞 Browser not supported...')
        sendSnackMessage(
          'Dialer not supported browser. Use Google Chrome.',
          'error'
        )
      })
      state.device.on('onLogin', () => {
        console.log('📞 Device logged in...')
        dialerServiceMutations.setDeviceLog('Logged in...')
        if (state.onReadyToCall) {
          console.log('📞 Used on ready to call...')
          state.onReadyToCall()
          state.onReadyToCall = null
        }
      })
      state.device.on('onLogout', () => {
        console.log('📞 Device logged out...')
        dialerServiceMutations.setDeviceLog('Logged out...')
        dialerServiceActions.resetState()
      })
      state.device.on('onLoginFailed', () => {
        console.log('📞 Device login failed...')
        dialerServiceMutations.setDeviceLog('Login failed...')
        if (!dialerServiceGetters.onCallState())
          dialerServiceActions.resetState()
      })
      state.device.on('onCallRemoteRinging', (callInfo) => {
        //This is the first event that we get callInfo on, different from twilio
        state.call = callInfo
        if (!getCurrentIncomingCallParams().isTransfer)
          dialerServiceActions.fetchCallByExternalId()
        console.log('📞 Remote ringing...', state.call)
        dialerServiceMutations.setDeviceLog('Remote ringing...')
      })
      state.device.on('onIncomingCallCanceled', (callInfo) => {
        console.log('📞 Incoming call cancelled...')
        removeIncomingCall(callInfo.callUUID)
        dialerServiceMutations.setDeviceLog('Incoming call cancelled...')
      })
      state.device.on('onCallFailed', (callInfo) => {
        console.log('📞 Call failed...', callInfo)
        if (callInfo === 'User Denied Media Access')
          EventBus.$emit('check-media-access')
        if (callInfo === 'Rejected' || callInfo.direction === 'incoming') {
          removeIncomingCall()
          return
        }
        dialerServiceMutations.setDeviceLog('Call failed...')
        dialerServiceActions.markCallDisconnected()
      })
      state.device.on('onCallAnswered', (callInfo) => {
        console.log('📞 Call answered...')
        /* --- This may be the right place to get the call details in case of MPC calls --- */
        if (dialerServiceGetters.getAudioTestCallInProgress())
          dialerServiceMutations.setAudioTestCallStatus('Connected')
        if (dialerServiceGetters.monitoringCallState()) setMicMute()
        if (!state.call) state.call = callInfo
        if (!getCurrentIncomingCallParams().isTransfer)
          dialerServiceActions.fetchCallByExternalId()

        if (callInfo.direction === 'incoming') {
          removeIncomingCall(callInfo.callUUID)
          dialerServiceMutations.setActiveCallAnswered(true)
          dialerServiceActions.startCallDuration()
        }
        dialerServiceActions.markCallAnswered()
      })
      state.device.on('onMediaConnected', () => {
        console.log('📞 Media connected...')
        dialerServiceMutations.setDeviceLog('Media connected...')
      })
      state.device.on('onCallTerminated', () => {
        console.log('📞 Call terminated...')
        dialerServiceMutations.setCallConnectivityIssues([])
        dialerServiceActions.markCallDisconnected()
      })
      state.device.on('onCalling', () => {
        console.log('📞 Calling...')

        if (FeatureFlags.friendlyOutboundConnectTone()) {
          const beepSound = document.createElement('audio')
          beepSound.src =
            'https://sdk.twilio.com/js/client/sounds/releases/1.0.0/outgoing.mp3'
          beepSound.play()

          setTimeout(() => {
            beepSound.pause()
            beepSound.remove()
          }, 1500)
        }

        dialerServiceMutations.setDeviceLog('Calling...')
      })
      state.device.on(
        'onIncomingCall',
        (callerID, extraHeaders, callInfo, callerName) => {
          console.log('📞 Incoming call...')
          if (state.provider === 'twilio') return
          if (state.incomingCalls.length) {
            // If there is already an incoming call, ignore this one
            // This is to prevent multiple incoming calls from showing up
            // because we will not currently handle multiple incoming calls
            console.log(
              '📞 Ignoring incoming call. Another incoming call is already ringing...'
            )
            // Using the device directly because this call will not be in the incomingCalls array
            ignoreIncomingCall(callInfo.callUUID)
            return
          }

          if (powerDialGetters.getIsPowerDialActive()) {
            // If power dial is active, ignore incoming calls
            console.log('📞 Ignoring incoming call. Power dial is active...')
            ignoreIncomingCall(callInfo.callUUID)
            return
          }

          state.incomingCalls.push({
            ...callInfo,
            callerID,
            ...extraHeaders,
            callerName,
          })
          EventBus.$emit('call-incoming')
          dialerServiceMutations.setDeviceLog(`Incoming call...`)
        }
      )
      state.device.on('onMediaPermission', () => {
        console.log('📞 Media permission...')
        dialerServiceMutations.setDeviceLog('Media permission...')
      })
      state.device.on('mediaMetrics', (mediaMetrics) => {
        // Clear out any previous call quality issues
        if (!mediaMetrics.active || mediaMetrics.active === 'false') {
          console.log('📞 Call Quality Warning Cleared: ', mediaMetrics.type)
          console.log(
            '📞 We have detected that the call quality conditions have improved. You should now experience improved call quality.'
          )
          dialerServiceMutations.setCallConnectivityIssues(
            dialerServiceGetters
              .getCallConnectivityIssues()
              .filter((issue) => issue.value !== mediaMetrics.type)
          )
        } else {
          // Check for call quality issues
          const callQualityObject = getCallQualityMessage(mediaMetrics.type)
          if (!callQualityObject) return
          console.log('📞 Call Quality Warning: ', callQualityObject.message)
          console.log(
            `📞 We have detected poor call quality conditions. ${callQualityObject.description} You may experience degraded call quality. Check your network connection.`
          )
          console.log('📞 Warning data: ', mediaMetrics)
          const uniqIssues = unionBy(
            dialerServiceGetters.getCallConnectivityIssues(),
            [callQualityObject],
            'value'
          )
          dialerServiceMutations.setCallConnectivityIssues(uniqIssues)
        }
      })
      state.device.on('onConnectionChange', (change) => {
        console.log('📞 Connection change...', change)
        dialerServiceMutations.setDeviceLog('Connection change...')
      })
      state.device.on('volume', ({ inputVolume, outputVolume }) => {
        // The event listener will receive inputVolume and outputVolume as percentages
        // of maximum volume represented by a floating point number
        // between 0.0 and 1.0 (inclusive).
        dialerServiceMutations.setVolume(Math.max(inputVolume, outputVolume))
      })
      state.device.on('onNoiseReductionReady', () => {
        dialerServiceMutations.setDeviceLog('Noise reduction ready...')
        // Check if the user has noise reduction enabled
        if (dialerServiceGetters.noiseSuppressionEnabled()) {
          state.device.startNoiseReduction()
        } else {
          state.device.stopNoiseReduction()
        }
      })
      state.device.on('audioDeviceChange', (deviceObj) => {
        console.log('📞 Audio device change...', deviceObj)
        dialerServiceActions.fetchAvailableAudioDevices()
      })
    },
    updatePhoneToken: () => {}, // Not needed for Plivo
    setupCallEventListener: () => {}, // Not needed for Plivo (handled in setupDeviceEventListener)
    toggleMute: () => setMicMute(!dialerServiceGetters.getIsMuted()),
    setMicMute,
    sendDigit: (digit) => {
      state.device.sendDtmf(digit)
    },
    disconnectCall: async () => {
      console.log('Plivo driver disconnectCall')
      state.device.hangup()
      if (
        !dialerServiceGetters.getCallId() &&
        !dialerServiceGetters.monitoringCallState()
      ) {
        await dialerServiceActions.fetchCallByExternalId()
      }
      dialerServiceActions.markCallDisconnected()
    },
    connectCall: async ({
      number,
      phoneNumberId,
      monitorCallId,
      audioTest,
    }) => {
      loginDeviceIfNotLoggedIn()
      const callAction = async () => {
        const dialSessionId = dialerServiceGetters.getCurrentDialSessionId()
        const isPDActive = powerDialGetters.getIsPowerDialActive()
        let headers = !monitorCallId
          ? {
              'X-PH-PhoneNumberId': phoneNumberId,
              'X-PH-ActionId': state.currentActionId,
              'X-PH-SymboSuggestionId': state.currentSuggestionId,
              'X-PH-CallerId': dialerServiceGetters.useLocalPresence()
                ? dialerServiceGetters.getLocalPresenceOutboundCaller()
                : dialerServiceGetters.getSelectedOutboundCaller(),
              'X-PH-ForceRecordOption':
                dialerServiceGetters.getForceRecordOption(),
              'X-PH-ExternalId': dialerServiceGetters.currentExternalId(),
              ...(dialSessionId && isPDActive
                ? { 'X-PH-DialSessionId': dialSessionId }
                : {}),
              ...(dialerServiceGetters.currentExternalObjectType()
                ? {
                    'X-PH-ExternalObjectType':
                      dialerServiceGetters.currentExternalObjectType(),
                  }
                : {}),
            }
          : {
              'X-PH-MonitorMpcUuid': monitorCallId.replaceAll('-', ''),
            }

        if (audioTest) headers = { 'X-PH-TestCall': true }

        let dialNumber
        if (monitorCallId) dialNumber = 'monitor'
        else if (dialSessionId && isPDActive && !number)
          dialNumber = 'dialsession'
        else if (audioTest) dialNumber = 'test'
        else dialNumber = sanitizePhoneNumber(number)

        console.log('Plivo driver connectCall', headers, dialNumber)
        await state.device.call(dialNumber, headers)
      }
      if (!state.device?.isLoggedIn) state.onReadyToCall = callAction
      else await callAction()
    },
    ignoreIncomingCall,
    acceptIncomingCall: (externalCallId) => {
      // 1. Get the incoming call id from the incomingCalls array (if call id is not provided, use the first one in the array)
      const incomingCall = getIncomingCall(externalCallId)
      // 2. Set state.call to the new incoming call
      state.call = incomingCall
      // 4. Remove the incoming call from the incomingCalls array
      removeIncomingCall(incomingCall.callUUID)
      // 5. Answer the call
      state.device.answer(incomingCall.callUUID)
    },
    destroyDevice: () => {
      if (!state.device) return
      console.log('📞 Destroying device...')
      state.device.logout()
      state.device = null
    },
    getAvailableDevices: async () => {
      if (!state.device) return
      state.availableDevices = await state.device.audio.availableDevices()
      state.selectedMicrophone =
        state.device.audio.microphoneDevices.get() || state.selectedMicrophone
      state.selectedSpeaker =
        state.device.audio.speakerDevices.get() || state.selectedSpeaker
      console.log('state.selectedMicrophone', state.selectedMicrophone)
      console.log('state.selectedSpeaker', state.selectedSpeaker)
    },
    setMicrophoneDevice: (deviceId) => {
      console.log('📞 Setting microphone device...', deviceId)
      const set = state.device.audio.microphoneDevices.set(deviceId)
      if (set) state.selectedMicrophone = deviceId
      else {
        console.log('📞 Could not set microphone device...')
      }
    },
    setSpeakerDevice: (deviceId) => {
      console.log('📞 Setting speaker device...', deviceId)
      const set = state.device.audio.speakerDevices.set(deviceId)
      if (set) state.selectedSpeaker = deviceId
      else {
        console.log('📞 Could not set speaker device...')
      }
    },
    startNoiseSuppression: () => state?.device?.startNoiseReduction(),
    stopNoiseSuppression: () => state?.device?.stopNoiseReduction(),
    forceDeviceRegister,
  }
}
